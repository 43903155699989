import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Hello Blog!")
  }


  loader(){
    // Add Loader
    $(event.target).find('#save-blog').hide()
    $("#loader").html('<i class="fa fa-circle-o-notch"></i>')
  }

  formSuccess(event){
    let [data, status, xhr] = event.detail;
    if (data.payload.blog_id != null){
      swal("Great", "Media Added", "success");
    }else{
      var msg = '';
      if (data.payload.status == "processing"){
        msg = 'Successfully Submitted! \nBlog will be publish once it gets approved by Management'
      }else if(data.payload.status == "draft"){
        msg = "Successfully Saved to Drafts"
      }
      swal({
        title: msg,
        text: "Redirecting...",
        type: "success",
        timer: 2000,
        button: false,
        showConfirmButton: false
      }).then(function(res){
        if (data.payload.status == "draft"){
          $.ajax({ 
            url: "/me/blog_drafts", 
            headers: { "Authorization": `Token ${TOKEN}` },
            success: function(){}
          })
        }else{
          $.ajax({ 
            url: "/me/blog_processing", 
            headers: { "Authorization": `Token ${TOKEN}` },
            success: function(){}
          })
        }
      });
    }
  }

  async preview(event){
    var file = event.target.files[0];
    if (file.type != 'image/png' && file.type != 'image/jpg' && file.type != 'image/jpeg'){
      $(event.target).val('')
      $(`#singleblog_cover`).text("Invalid File Type. Please select an image");
      return
    }
    if (file.size <= 2048000){
      var url = URL.createObjectURL(file);
      var img64 = await toBase64(file);
      $(".cover-img #preview").replaceWith(`
        <div id="preview" class="m-b-10">    
          <div class='uploaded'> 
            <img class="img-fluid" width="300" src="${url}">
          </div>
        </div>
      `);
      $(`.err`).text('');
    }else{
      $(event.target).val('')
      $(`#singleblog_cover`).text("Files greater than 2 MB are not allowed");
    } 
  }


  formError(event) {
    let [data, status, xhr] = event.detail;
    if (xhr.status == 401) {
      swal("Oops", "You need to login in first", "error");
    }else{
      if (typeof(data.payload.errors) == "object"){
        Object.keys(data.payload.errors).forEach( (key) => { 
          $(`#singleblog_${key}`).text(data.payload.errors[key].join(", ")) 
          $('html, body').animate({ scrollTop: $(`#singleblog_${key}`).position().top }, 50, 'swing')
        })
      }else if(typeof(data.payload.errors) == "string") {
        $(`.err`).text(data.payload.errors)
        $('html, body').animate({ scrollTop: $(`.err`).position().top }, 50, 'swing')
      } 

      if (data.payload.blog_id != null){
        swal("Great", "Media Added", "success");
      }
      $(event.target).find('#save-blog').show()
      $("#loader").html(null)
    }
  }

  // clear(event){
  //   $(".blog-form form")[0].reset()
  // }
}
