$(function(){
  function isEmpty(object) {
    return (
      (!object)
      || (object === undefined)
      || (object === null)
      || (object === '')
      || ((object?.length !== undefined) && (object.length === 0))
      || (typeof object === 'object' && Object.keys(object).length === 0) 
    );
  }

  window.humanize = function(str) {
    return str
        .replace(/^[\s_]+|[\s_]+$/g, '')
        .replace(/[_\s]+/g, ' ')
        .replace(/^[a-z]/, function(m) { return m.toUpperCase(); });
  }

  window.debounce = function(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
  };

  window.TOKEN = window.readCookie("_priac_jwt");

  window.selectDropdownById = function(key, val){
    let num = isNaN(parseInt(val)) ? 0 : parseInt(val)
    $(`button[data-id='${key}']`).trigger("click")
    $(`button[data-id='${key}']`)
      .next(".dropdown-menu")
      .find(`ul li:nth-child(${ num +1}) a`)
      .trigger("click")
  }

  window.cl = function(msg){
    console.log(msg)
  }

  window.resetPatientData = function(patientParams){
    for (const key in patientParams) {
      if (Object.hasOwnProperty.call(patientParams, key)) {
        var $element = $(`#patient_${key}`)

        // Patient Feilds
        if(!$($element).is("select")){
          $(`input[name="patient[${key}]"]`).val(null)
        }else if ($element.is(":checkbox")){
          $(`#patient_${key}`).prop('checked', null);
        }else{
          $(`#patient_${key}`, null).trigger('change')
        }
      }

      if ((key == "appointments") && patientParams.appointments.length > 0){
        var appt = patientParams.appointments.filter(m => m.status != "cancelled")[0]
        for (const k in appt) {
          var $apptElement = $(`#appointment_${k}`)

          // Appointment Fields
          if(!$($apptElement).is("select")){
            $(`input[name="appointment[${k}]"]`).val(null)
          }else if ($apptElement.is(":checkbox")){
            $(`#appointment_${k}`).prop('checked', null);
          }else{
            $(`#appointment_${k}`, null).trigger('change')
          }
        }
      }
      $("#save_update").attr("data-appointmentId", "")
      $(".generate-invoice").attr("data-appointmentId", "")
    }
    $(".other-input").remove();
  }


  window.fillPatientData = function(patientParams){
    resetPatientData(patientParams)
    for (const key in patientParams) {
      if (Object.hasOwnProperty.call(patientParams, key)) {
        var val = patientParams[key] || "";
        var $element = $(`#patient_${key}`)

        // Patient Feilds
        if(!$($element).is("select")){
          $(`input[name="patient[${key}]"]`).val(val)
        }else if ($element.is(":checkbox")){
          $(`#patient_${key}`).prop('checked', val);
        }else{
          $(`#patient_${key}`).val(val).trigger("change")
        }
      }
      
      if ((key == "appointments") && patientParams.appointments.length > 0){
        var appt = patientParams.appointments.filter(m => m.status == "completed")[0]
        for (const k in appt) {
          var apptVal = appt[k] || "";
          var $apptElement = $(`#appointment_${k}`)
          
          // Appointment Fields
          if(!$($apptElement).is("select")){
            $(`input[name="appointment[${k}]"]`).val(apptVal)
          }else if ($apptElement.is(":checkbox")){
            $(`#appointment_${k}`).prop('checked', apptVal);
          }
          else if(k.indexOf("_id") == -1){
            $(`button[data-id='appointment_${k}']`).trigger("click")
            $(`button[data-id='appointment_${k}']`)
              .next(".dropdown-menu")
              .find(`span:contains(${ humanize(apptVal) })`)
              .parent().trigger("click")
          }else{
            $(`#appointment_${k}`).val(apptVal).trigger('change')
          }
          $("#appointment_visit_type").val("old").trigger("change");
        }
        if (appt){
          $("#save_update").attr("data-appointmentId", appt.id)
        }
      }
    }
  }

  window.createAppointmentTabs = function(res, type){
    var def = null;
    var completedAppointments = res.payload.appointments.filter(a => (a.status == "completed" || a.status == "paid"))

    if (completedAppointments.length == 5 && type != "all"){
      def = `<li class="p-1 text-theme"> 
          <span class="badge appointment-tabs badge-sm badge-warning"
            data-action="click->prescription#loadAllAppointments"
            data-patientid="${res.payload.id}"
            >
              Load All
          </span> 
        </li>`;
    }
    $(".appts").html(def);
    todays_month = new Date(Date.now()).toDateString().split(" ")[1]
    todays_date = new Date(Date.now()).toDateString().split(" ")[2]
    todays_year = new Date(Date.now()).toDateString().split(" ")[3]

    for (let index = completedAppointments.length - 1; index >= 0; index--) {
      var appt = completedAppointments[index];
      var visit_date = new Date(Date.parse(appt.visit_date)).toDateString()
      var bage = '';
      
      if ( `${visit_date}` == new Date().toDateString() ){
        bage = "bg-theme"
      }

      $(".appts").append(`
        <li class="p-1 text-theme"> 
          <span class="badge appointment-tabs badge-sm badge-theme ${bage}"
            data-action="click->prescription#fillAppointment" id="appointmentTab${appt.id}"
            data-prescriptionId="${appt.prescription_id || ''}" 
            data-appointmentId="${appt.id}">
              ${visit_date}
          </span> 
        </li>
      `)
    }

    // Add today's tab
    // $(".appts").append(`
    //   <li class="p-1 text-theme"> 
    //     <span class="badge appointment-tabs badge-sm badge-theme bg-theme"
    //       data-action="click->prescription#fillAppointment" 
    //       id="appointmentTab"
    //       data-prescriptionId="${appt.prescription_id}" 
    //       data-appointmentId="${appt.id}"
    //       >
    //         ${todays_date} ${todays_month}, ${todays_year}
    //     </span> 
    //   </li>
    // `)
  }

  window.parseDate = function(date){
    todays_month = new Date(date).toDateString().split(" ")[1]
    todays_date = new Date(date).toDateString().split(" ")[2]
    todays_year = new Date(date).toDateString().split(" ")[3]
    return `${todays_date} ${todays_month}, ${todays_year}`
  }


  window.getPatientAndFillData = function(patientID){
    $.ajax({
      url: `${HOST}/api/v1/patients/${ patientID }`,
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        console.log(res)
        patientParams = res.payload
        fillPatientData(patientParams)
        
        // If its doctor's screen
        if (window.location.pathname.indexOf("prescription") != -1){
          
          // If at least one appointment exists
          var appointment = res.payload.appointments.filter(m => m.status != "cancelled")[0]
          
          if (appointment){
            // Create appointment tabs 
            var prescriptionId = appointment.prescription_id;
            createAppointmentTabs(res)

            // Fill prescription data of first appointment
            fillPrescriptionData(prescriptionId)
          }
        }
      },
      error: function(){}
    })
  }

  window.fillMedicineTable = function(medicines, action){

    // Check presence of existing medicines and filter the duplicates
    var filteredmedicines = []
    $("input[name='treatment_medicine[name]']").map(function(e) { 
      let $val = $(this).val()
      if ($val){
        let entry = medicines.find(x => x.name === $val)
        if (entry) { 
          filteredmedicines.push(entry) 
        }else{
          filteredmedicines.push({
            name: $val,
            description: $(this).parents("tr").find("td:nth-child(2) input").val(),
            remark: $(this).parents("tr").find("td:nth-child(3) input").val()
          })
        }
      }
    })

    if (action){
      // Append the new added medicines to the old list
      filteredmedicines = filteredmedicines.concat(medicines) 
      filteredmedicines = [...new Set(filteredmedicines.filter(item => item.name))]
    }else[
      filteredmedicines = [...new Set(medicines.filter(item => item.name))]
    ]
    
    // Get ids of all medicines
    var medicineNames = []; 
    filteredmedicines.map(function(e) { medicineNames.push(e.name) })

    $.ajax({
      url: `/api/v1/medicines/id_by_name`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      method: "GET",
      data: {
        names: medicineNames.join(", ")
      },
      success: function(res){
        console.log(res)
        Object.keys(res.payload).forEach(function(p) {
          var med = filteredmedicines.find((obj) => obj.name === p )
          index = filteredmedicines.findIndex(obj => obj.name === p);
          filteredmedicines.splice(index, 1);
          med["medicine_id"] = res.payload[p]
          filteredmedicines.push(med)
        })
        generateMedicineTable(filteredmedicines)
        $(".add-more-medicine").trigger('click');
      },
      error: function(){
        generateMedicineTable(filteredmedicines)
        $(".add-more-medicine").trigger('click');
      }
    })    
  }

  $(".add-more-medicine").click(function(){
    $(this).parents("table").find('tbody').append(`
      <tr>
        <td class="p-1">
          <div class="form-group">
            <input type="text" name="treatment_medicine[name]" id="treatment_medicine_name" class="form-control medicine" autocomplete="off"  placeholder="Medicine" data-action="input->prescription#medicinesList">
            <input type="hidden" name="treatment_medicine[medicine_id]" value="" />
          </div>
          <div class="medicinesList custom-select-list"> 
          </div>
        </td>
        <td class="p-1">
          <div class="form-group">
            <input type="text" name="treatment_medicine[description]" id="treatment_medicine_description" class="form-control description" autocomplete="off"  placeholder="Description" data-action="input->prescription#descriptionsList">
          </div>
          <div class="descriptionsList custom-select-list"> 
          </div>
        </td>
        <td class="p-1">
          <div class="form-group">
            <input type="text" name="treatment_medicine[remark]" id="treatment_medicine_remark" class="form-control remark" autocomplete="off"  placeholder="Remarks" data-action="input->prescription#remarksList">
          </div>
          <div class="remarksList custom-select-list"> 
          </div>
        </td>
        <td class="p-1">
          <div class="text-center">
            <a class="btn btn-theme btn-sm" data-action="click->prescription#removeMedicine" data-remote="true" href="/prescription">Remove</a>
          </div>
        </td>
      </tr>
    `)
  })

  window.generateMedicineTable = function(filteredmedicines){
    var tr = '';
    $(`.treatment-table tbody`).html(null)
    for (let m = 0; m < filteredmedicines.length; m++) {
      var med = filteredmedicines[m];
      tr = tr + `<tr>
          <td class="p-1">
            <div class="form-group">
              <input type="text" name="treatment_medicine[name]" id="treatment_medicine_name" class="form-control ${med.medicine_id ? "" : 'border-red'} medicine" autocomplete="off" placeholder="Medicine" data-medicineid="${med.medicine_id}" value="${med.name}" data-action="input->prescription#medicinesList">
              <input type="hidden" name="treatment_medicine[medicine_id]" value="${med.medicine_id}" />
            </div>
            <div class="medicinesList custom-select-list"> 
            </div>
          </td>
          <td class="p-1">
            <div class="form-group">
              <input type="text" name="treatment_medicine[description]" id="treatment_medicine_description" class="form-control description" autocomplete="off" placeholder="Description" value="${med.description} "data-action="input->prescription#descriptionsList">
            </div>
            <div class="descriptionsList custom-select-list"> 
            </div>
          </td>
          <td class="p-1">
            <div class="form-group">
              <input type="text" name="treatment_medicine[remark]" id="treatment_medicine_remark" class="form-control remark" autocomplete="off" placeholder="Remarks" value="${med.remark || ''}" data-action="input->prescription#remarksList">
            </div>
            <div class="remarksList custom-select-list"> 
            </div>
          </td>
          <td class="p-1">
            <div class="text-center">
              <a class="btn btn-theme btn-sm" data-action="click->prescription#removeMedicine" data-remote="true" href="/prescription">Remove</a>
            </div>
          </td>
        </tr>`;

    }
    $(`.treatment-table tbody`).prepend(tr)
  }

  window.fillTreatmentData = function(treatmentId){
    if (treatmentId){
      $.ajax({
        url: `/api/v1/treatments/${treatmentId}`, 
        headers: { "Authorization": `Token ${TOKEN}` },
        success: function(res){
          // $(`.treatment-table tbody`).html(null)
          $(`#prescription_diagnosis_`).val(res.payload.diagnosis)
          $(`#prescription_medical_tests_`).val(res.payload.medical_tests)
          $(`#prescription_self_notes_`).val(res.payload.self_notes)
          $(`#prescription_special_instructions_`).val(res.payload.special_instructions)
          fillMedicineTable(res.payload.medicines, true)
        },
        error: function(res){}
      });
    }
  }

  window.clearTreatmentAndPrescription = function(){
    $(`.treatment-table tbody`).html(null);
    $(".special-inst-code").show()
    $(`#prescription_diagnosis_`).val(null);
    $(`#prescription_notes_`).val(null);
    $(`#prescription_self_notes_`).val(null);
    $(`#prescription_special_instructions_`).val(null);
    $(`#prescription_followup_`).val(null);
    $(`#prescription_additional_tests_`).val(null);
    $(`#prescription_medical_tests_`).val(null);
    $(".add-more-medicine").trigger('click');
    $(`input[name='prescription[daily_exercise]']`).prop( "checked", false );
    $(`input[name='prescription[dietitian]`).prop( "checked", false);
    $(`input[name='prescription[physiotherapist]']`).prop("checked", false);
    $(`input[name='prescription[psychologist]']`).prop( "checked", false);
    $("#saveprint, #save_update").attr("data-prescriptionid", '')
    return;
  }

  window.fillPrescriptionData = function(prescriptionId){

    // If no past prescription present for clicked patient, empty the fields
    if (!prescriptionId){
      clearTreatmentAndPrescription()
      $(`#prescription_treatment_id`).val(null).trigger("change");
      return
    }
    $.ajax({
      url: `${HOST}/api/v1/prescriptions/${prescriptionId}`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
          console.log(res)
          $(`#prescription_diagnosis_`).val(res.payload.diagnosis_list)
          $(`#prescription_notes_`).val(res.payload.notes)
          $(`#prescription_medical_tests_`).val(res.payload.medical_tests_list)
          $(`#prescription_self_notes_`).val(res.payload.self_notes)
          $(`#prescription_special_instructions_`).val(res.payload.special_instructions)
          $(`#prescription_followup_`).val(res.payload.followup);
          
          $(".watched-videos").html(null)
          for (let index = 0; index < res.payload.videos.length; index++) {
            const video = res.payload.videos[index];
            $(".watched-videos").append(`<li> ${video} </li>`)
            // $(`#prescription_videos_`).next().next(`.dropdown-menu`).find(`li a span:contains(${video})`).css("color", "red");
          } 

          if (res.payload.videos.length > 0){
            let last = res.payload.videos[res.payload.videos.length-1]
            let now = $(`#prescription_videos_ option:contains(${last})`).next().html()
            $(`#prescription_videos_`).val(now).trigger("change");
          }else{
            $(`#prescription_videos_`).val(null).trigger("change");
          }



          $(`#prescription_referred_by_`).val(res.payload.referred_by);
          $(`#prescription_additional_tests_`).val(res.payload.additional_tests);
          $(`input[name='prescription[daily_exercise]']`).prop( "checked", res.payload.daily_exercise );
          $(`input[name='prescription[dietitian]`).prop( "checked", res.payload.dietitian);
          $(`input[name='prescription[physiotherapist]']`).prop("checked", res.payload.physiotherapist);
          $(`input[name='prescription[psychologist]']`).prop( "checked", res.payload.psychologist);
          $(`#prescription_treatment_id`).val(res.payload.treatment?.id).trigger('change')

          // Fill medicines
          medicines = res.payload.medicines
          fillMedicineTable(medicines, false)
        },
      error: function(){
      }
    })
  }

  window.newInvestigations =  function(investigations, appointmentId){
    $(`.investigations`).html(null)
    for (let index = 0; index < investigations.length; index++) {
      const test = investigations[index];
      let crossBtn = '';
      if (appointmentId){
        crossBtn = `<i data-appointmentid="${appointmentId}" class="text-danger fa fa-times" data-action="click->prescription#removeInvestigation"> 
           </i>`
      }
      let fields = `
          <div data-test="${test.name.toUpperCase()}">
            ${crossBtn}
            <label class="display-7"> ${test.name.toUpperCase()} </label>
            <div class="d-flex mr-3">
              <div class="small-field">
                <input type="text" name="investigation[before_${test.name.toLowerCase()}]" id="before_${test.name.toLowerCase()}" class="form-control text-${test.before_color}" placeholder="Before ${test.name.toUpperCase()}" readonly="true" value="${test.before_value}">
                <small class="text-center d-block">${test.before_date || ''}</small>
              </div>
              <div class="small-field">
                <input type="text" name="investigation[after_${test.name.toLowerCase()}]" id="${test.name.toLowerCase()}" class="form-control text-${test.after_color}" placeholder="${test.name.toLocaleUpperCase()}" readonly="true" value="${test.after_value}">
                <small class="text-center d-block">${test.after_date || ''}</small>
              </div>
            </div>
          </div>`;
      $(`.investigations`).append(fields)

      if ( $(`#medical_param`).length > 0 ){
        $(`#medical_param option[value='${test.name}']`).remove()
        $('#medical_param').selectpicker('refresh');
      }
    }
  }

  window.printPage = function(htmlContent) {
    var iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '1px';
    iframe.style.height = '1px';
    iframe.style.left = '-9999px';
  
    var blob = new Blob([htmlContent], { type: 'text/html' });
    var url = URL.createObjectURL(blob);
    iframe.src = url;
    document.body.appendChild(iframe);

    iframe.onload = function() {
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
      // document.body.removeChild(iframe);
    };
  }  


  window.generateConsultingInvoice = function(apptId){

    // Generate Invoice on billing screen only
    flashToast("Generating Invoice...", "info")
    $.ajax({
      url: `${HOST}/api/v1/appointments/${apptId}/invoice`,
      headers: { "Authorization": `Token ${TOKEN}` },
      method: "POST",
      data: {
        invoice_type: "consultancy"
      },
      success: function(inv){
        if (inv.payload.invoices.length > 0){
          printPage(inv.payload.invoices[0].content);
          refreshSlots()
          $(".generate-invoice").removeClass("d-none")
        }else{
          flashToast("Something Happened", "warning")
        }
      },
      error: function(){}
    })
  }


  window.generateTestsInvoice = function(appointment_id, data){

    $(".save_print").addClass("d-none")
    flashToast("Generating Invoice...", "info")
    // Send request to server to generate invoice

    var obj = {
      tests: data.tests,
      dob: data.dob,
      uid: data.uid,
      gender: data.gender,
      name: (data.name || null), 
      mobile: (data.mobile || null)
    }

    if (data["admissionId"]){
      obj.admission_id = data['admissionId']
    }

    $.ajax({
      url: `${HOST}/api/v1/appointments/${appointment_id || ''}/invoice`, 
      method: "POST",
      data: obj,
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(inv){
        console.log(inv)
        for (let index = 0; index < inv.payload.invoices.length; index++) {
          let invoice = inv.payload.invoices[index];
          $(`#${invoice.department_name.toLowerCase()}`)
            .find(".save_print, .test-reprint, .test-refund")
            .attr("data-invoice_id", invoice.id)
        }
        if (inv.payload.invoices.length > 0){
          printPage(inv.payload.invoices[0].content);
          refreshSlots()
        }else{
          flashToast("Something Happened", "warning")
        }
        $(".save_print").removeClass("d-none")
      },
      error: function(err){
        flashToast(`${err.responseJSON.status.message}`, "warning")
        $(".save_print").removeClass("d-none")
      }
    })
  }
  

  $(document).on('click', ".notification, .profile-options", function(){
    // $(".notification + .dropdown-menu, .profile-options + .dropdown-menu").hide()
    $(this).next(".dropdown-menu").toggle()
  });

  var selectedIndex = -1;

  // Navigate within the list
  $(document).on("keydown", "#search-patient", function(e) {
    var patients = $(".searched-patient-name");
    if (e.keyCode === 38) { // Up arrow key
      e.preventDefault();
      if (selectedIndex > 0) {
        patients.eq(selectedIndex).removeClass("selected");
        selectedIndex--;
        patients.eq(selectedIndex).addClass("selected");
      }
    } else if (e.keyCode === 40) { // Down arrow key
      e.preventDefault();
      if (selectedIndex < patients.length - 1) {
        if (selectedIndex > -1) {
          patients.eq(selectedIndex).removeClass("selected");
        }
        selectedIndex++;
        patients.eq(selectedIndex).addClass("selected");
      }
    } else if (e.keyCode === 13) { // Enter key
      e.preventDefault();
      var selectedPatient = $(".searched-patient-name.selected");
      if (selectedPatient.length > 0) {
        var fullName = selectedPatient.data("full_name");
        var clickEvent = $.Event("click");
        $(".searched-patient-name.selected").trigger(clickEvent)
        $("#search-patient").val(fullName);
      }
    }
  });
  

  // Search Patient
  $(document).on("input propertychange paste", "#search-patient", debounce(function(e){
    selectedIndex = -1;
    $.ajax({
      url: `/api/v1/patients?q=${$(this).val()}`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        console.log(res)
        if (res && res.payload){
          var data = res.payload.patients;
          var patientsLi = ''
          if (window.location.pathname.indexOf("quick") != -1){
            page = "quick"
          }else if(window.location.pathname.indexOf("billing") != -1){
            page = "billing"
          }else if(window.location.pathname.indexOf("appointment") != -1){
            page = "appointment"
          }else if(window.location.pathname.indexOf("lab") != -1){
            page = "lab"
          }else if(window.location.pathname.indexOf("prescription") != -1){
            page = "prescription"
          }else if(window.location.pathname.indexOf("pharmacy") != -1){
            page = "pharmacy"
          }

          for (let index = 0; index < data.length; index++) {
            var selectedClass = index === selectedIndex ? "selected" : "";
            patientsLi += `<a 
            href="#" 
            class="searched-patient-name ${selectedClass}"
            data-action="click->${page}#fillPatient"
            data-patientId="${data[index].id}" 
            data-uid="${data[index].uid}" 
            data-full_name="${data[index].full_name}"  
            data-remote="true"> 
              ${data[index].uid} - ${data[index].full_name} 
            </a>`
          }
          $("#patient-search-results").html(patientsLi)
          $("#patient-search-results").show();
        }
        $("#patient-search-results").show()
      },
      error: function(res){
        
      }
    })
    return false;
  }, 500))


  // Hide the patient searched list
  $(document).on('click', function(){
    $("#patient-search-results").hide();
  })

  $(document).on("input paste", "#patient_mobile", debounce(function(){
    var mobile = $(this).val()
    $.ajax({
      url: `${HOST}/api/v1/patients/mobile_no_check?mobile=${mobile}`,
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        console.log(res)
        if (res.payload.present){
          flashToast("Mobile number is already in use", "warning")
        }
      }
    })
  }, 1000))

  // Cancel Appointment
  $(document).on("click", '.remove-appointment', function() {
    if (window.confirm("Are you sure?")){
      $(this).parents('tr').remove()
      flashToast("Deleted Successfully", "info")
    }
  });

  // Update Patient data
  $(document).on("click", ".update-patient-info", function(en){ 
    en.preventDefault()
    patientParams = [
      "uid",
      "full_name",
      "email",
      "mobile",
      "dob",
      "age",
      "gender",
      "marital_status",
      "address",
      "pincode",
      "diet",
      "city_id",
      "organization_id",
      "occupation_id",
      "education_id",
    ]

    obj = {}
    for (let index = 0; index < patientParams.length; index++) {
      const element = patientParams[index];
      if ($(`#patient_${element}`).val()) {
        obj[element] = $(`#patient_${element}`).val()
      }
    }

    if (!isEmpty(obj)){
      if ($(this).attr("data-patientId") == undefined){
        patientID =  ''
        method = "POST"
      }else{
        patientID = $(this).attr("data-patientId")
        method = "PUT"
      }

      $.ajax({
        url: `${HOST}/api/v1/patients/${patientID}`,
        headers: { "Authorization": `Token ${TOKEN}` },
        data: { patient: obj },
        method: method,
        success: function(res){
          console.log(res)
          flashToast("Updated Successfully", "info")
          $(".update-patient-info").attr('data-patientId', res.payload.id)
        },
        error: function(err){
          console.log(err)
          flashToast(err.responseJSON.status.message, "warning")
        } 
      })
    }
    return false;
  })


  window.pickerFormatDate = function(d) {
    let date = new Date(d)
    const pad = (num) => (num < 10 ? '0' + num : num);

    let year = date.getFullYear();
    let month = pad(date.getMonth() + 1); // Months are zero-indexed
    let day = pad(date.getDate());
    let hours = pad(date.getHours());
    let minutes = pad(date.getMinutes());

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  window.updateAppointmentAndGenerateInvoice = function(objAppt, appointmentId){
    $(".generate-invoice").addClass("d-none")
    
    $.ajax({
      url: `${HOST}/api/v1/appointments/${appointmentId}`,
      headers: { "Authorization": `Token ${TOKEN}` },
      data: { appointment: objAppt },
      method: "PUT",
      success: function(pp){
        console.log(pp)
        flashToast("Appointment Updated", "info")

        // TO DO: Refresh componenets
        // debugger

        // Generate Invoice on billing screen only
        if (window.location.pathname.indexOf("billing") != -1){
          generateConsultingInvoice(pp.payload.id)
          $(".generate-invoice").removeClass("d-none")
        }else if(window.location.pathname.indexOf("prescription") != -1){
          $("#save_update, #saveprint").attr("data-appointmentId", pp.payload.id)
        }else{
          window.location.reload();
        }
      },
      error: function(err){
        $(".generate-invoice").removeClass("d-none")
      }
    })
  }

  // Create new Appointment
  window.takeAppointment = function(en){ 
    $(".generate-invoice").addClass("d-none")
    
    // Save patient before appointment
    patientID = $(".update-patient-info").attr("data-patientId")
    
    if (!patientID){
      $(".update-patient-info").trigger("click")
      patientID = $(".update-patient-info").attr("data-patientId")
    }

    appointmentParams = [
      "code",
      "visit_type",
      "visit_date",
      "duration",
      "active",
      "status",
      "comments",
      "description",
      "rescheduled_at",
      "appointment_slot_id",
      "department_id",
      "consultant_id",
      "doctor_id",
      "patient_id",
      "height",
      "weight",
      "fever",
      "bp_s",
      "bp_d",
      "bmi"
    ]

    appointmentId = '';
    method = "POST"

    objAppt = { patient_id:  patientID }
    for (let i = 0; i < appointmentParams.length; i++) {
      const element = appointmentParams[i];
      if ($(`#appointment_${element}`).val()) {
        objAppt[element] = $(`#appointment_${element}`).val()
      }
    }

    $.ajax({
      url: `${HOST}/api/v1/appointments/`,
      headers: { "Authorization": `Token ${TOKEN}` },
      data: { appointment: objAppt },
      method: method,
      success: function(pp){
        console.log(pp)
        flashToast("Successfully Appointed", "info")

        // TO DO: Refresh componenets
        // debugger

        // Generate Invoice on billing screen only
        if (window.location.pathname.indexOf("billing") != -1){
          generateConsultingInvoice(pp.payload.id)
          $(".generate-invoice").removeClass("d-none")
        }else if(window.location.pathname.indexOf("prescription") != -1){
          $("#save_update").attr("data-appointmentId", pp.payload.id)
          $("#saveprint").attr("data-appointmentId", pp.payload.id)
        }else{
          window.location.reload();
        }
      },
      error: function(err, obj){
        
        try {
          var msg = err.responseJSON.status.message
        }catch {
          var msg = "Failed. Please fill correct appointment data"
        }
        if (err.responseJSON.status.code == "006"){

          let decision = confirm(`${msg} \nDo you want to cancel & reschedule?`);
          if (decision){
            
            // Reschedule Appointment
            $.ajax({
              url: `${HOST}/api/v1/appointments/${err.responseJSON.payload.id}/reschedule`,
              headers: { "Authorization": `Token ${TOKEN}` },
              data: { appointment: objAppt },
              method: "POST",
              success: function(pp){
                flashToast("Successfully Appointed", "info")
                if (window.location.pathname.indexOf("billing") != -1){
                  generateConsultingInvoice(pp.payload.id)
                  $(".generate-invoice").removeClass("d-none")
                }
                else if(window.location.pathname.indexOf("prescription") != -1){
                }else{
                  window.location.reload();
                }
              },
              error: function(err){
                try {
                  var msg = err.responseJSON.status.message
                }catch {
                  var msg = "Failed. Please fill correct appointment data"
                }
                $(".generate-invoice").removeClass("d-none")
                flashToast(msg, "warning");
              }
            })
          }else{
            $(".generate-invoice").removeClass("d-none")
          }
        }else{
          $(".generate-invoice").removeClass("d-none")
          flashToast(msg, "warning");
        }
      } 
    })

    return false;
  }
  
  $(document).on("click", ".take-appointment", function(en){
    en.preventDefault()
    takeAppointment()
  });

  $(".toastify-btn").on("click", function(en){
    en.preventDefault();
    flashToast("Updated Successfully", "info")
    return false;
  })

  window.selectOption = function(el) {
    var list = el.parent().next()
    if (list.length == 0){
      list = el.parent()
    }
    var entry = list.find(".med.selected").text().trim()
    if (entry.length > 0) {
      if (list.hasClass("medicalTestsList")){
        var oldValue = $("#prescription_medical_tests_")
                .val()
                .split(",")
                .map(item => item.trim());
        oldValue.pop()
        oldValue.push(entry)
        var tests = [...new Set(oldValue)];
        $("#prescription_medical_tests_").val(`${tests.join(", ")}`)
      
      }else if (list.hasClass("followUpList")){
        var oldValue = $("#prescription_followup_")
                .val()
                .split(",")
                .map(item => item.trim());
        oldValue.pop()
        oldValue.push(entry)
        var tests = [...new Set(oldValue)];
        console.log(tests)
        $("#prescription_followup_").val(`${tests.join(", ")}`)
      
      }else if (list.hasClass("videosList")){
        var oldValue = $("#prescription_videos_")
                .val()
                .split(",")
                .map(item => item.trim());
        oldValue.pop()
        oldValue.push(entry)
        var tests = [...new Set(oldValue)];
        console.log(tests)
        $("#prescription_videos_").val(`${tests.join(", ")}`)
      
      }else{

        // Condition for medicine duplicacy check
        if (el.attr("id") == "treatment_medicine_name"){
          var names = []
          var medicineID = list.find(".med.selected").attr("data-medicineid")
          $("input[name='treatment_medicine[name]']").not(el).each(function() { 
            names.push($(this).val()) 
          })
          el.val(entry);

          names = encodeURIComponent(names.join(","));
          current_name = encodeURIComponent(entry);
          source = "yes"

          // Allow duplicates on pharmacy billing
          if (window.location.href.indexOf("/pharmacy") != -1){
            source = "no"
          }

          // Empty batch field
          try { el.parents("tr").find(".batch select").html(null) } catch {}

          $.ajax({
            url:  `${HOST}/api/v1/medicines/medicine_by_name?names=${names}&current_name=${current_name}&source=${source}`,
            headers: { "Authorization": `Token ${TOKEN}` },
            success: function(res){
              if (res.payload.presence){
                el.focus()
                flashToast("Medicine already present in the list", "warning")
                fillEntry(el, null, null)
              }else{
                fillEntry(el, entry, medicineID)
                el.removeClass("border-red");
                el.closest("tr").find("#returned").attr("data-medicine-id", medicineID);
                fillMedicineMetaInfo(el)
              }
            },
            error: function(err){ }
          })
        }else{
          el.val(entry);
        }
      }
    }
    list.html(null);
    list.hide();
  }

  window.fillEntry = function(el, entry, medicineID){
    el.attr('data-medicineid', medicineID)
    el.next(`input[name='treatment_medicine[medicine_id]']`).val(medicineID)
    el.val(entry);
  }

  window.refreshSlots = function(el){
    var date = null;
    if (el){ 
      date = el.val();
    }else{
      date = $("#appointment_visit_date").val()
    }
    $.ajax({
      url:  `${HOST}/api/v1/appointments/available_slots?date=${date}`,
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        var data = res.payload.slots
        var options = '<option> </option>';
        for (let index = 0; index < data.length; index++) {
          var option_id = data[index].id;
          var option_time = data[index].time;
          options += `<option value="${option_id}"> ${option_time} </option>`
        }
        $("#appointment_appointment_slot_id").html(options)
      },
      error: function(res){
        
      }
    })
  }

  $(document).on("change", "#appointment_visit_date", function(){
    refreshSlots($(this))
  })

  $(document).on("keydown focus", "input[name='treatment_medicine[name]']", function(){
    if (!$(this).val()){
      fillEntry($(this), null, null)
    }
  })

  $(document).on("keydown focus", `input[name='treatment_medicine[name]'], 
      input[name='treatment_medicine[description]'], 
      input[name='appointment[referrer_id]'], 
      input[name='treatment_medicine[remark]'], 
      textarea[name='prescription[followup][]'],
      #prescription_videos_,
      textarea[name='prescription[additional_tests][]'],
      textarea[name='prescription[medical_tests][]']
    `, function(e) {
    var $el = $(this)
    $list = $(this).parent().next()
    if ($el.val() == '' && $(".custom-select-list").is(":visible")){
      $(".custom-select-list").hide()
    }

    if ($(".custom-select-list:visible").length > 1){
      $(".custom-select-list").hide()
    }

    if (e.keyCode == 13) { // enter
      if ($list.is(":visible")) {
        selectOption($el);
      }else {
        $list.show();
      }
      return false;
    }

    if (e.keyCode == 9) { // tab
      
      if($el.attr("id") == "prescription_followup_") {
        // From Followups, move focus to medical tests
        var radioButton = $('#prescription_medical_tests_');
        if ($list.is(":visible")) {
          selectOption($el);
        } else {
          $list.show();
        }
        radioButton.focus();
        return false;

      }else if ($el.attr("id") == "prescription_medical_tests_"){
        // From Medical Tests Tests, move focus to Additional tests
        var radioButton = $('#prescription_additional_tests_');
        radioButton.focus();

      }else if ($el.attr("id") == "prescription_additional_tests_"){
        // From Additional Tests, move focus to daily exercise 
        var videos = $('#prescription_videos_');
        videos.focus();
      
      }else if ($el.attr("id") == "prescription_videos_"){
          // From Additional Tests, move focus to daily exercise 
          var radioButton = $('#cb_daily_exercise');
          radioButton.focus();
          return false;
  
      }else{

        /* 
          If focus is on medicines sections 
        */
        if ($list.is(":visible")) {
          selectOption($el);
        } else {
          $list.show();
        }  

        $row = $el.parents("tr")
        // get the all 3 ids to find the next input it should jump to
        let inputs = []; 
        $row.find("input[type='text']:nth-child(1)").map( function() { inputs.push($(this).attr("id")) })
        if ($($row.find("input[type='text']:nth-child(1)")[0]).val()){
          $($row.find("input[type='text']:nth-child(1)")[ inputs.indexOf($el.attr("id")) + 1]).select().focus();
        }else{
          if ($($row.find("input[type='text']:nth-child(1)")[0]).val()){
            return false;
          }
        }

        var values = []; 
        $row.find("input").map( function(){ 
          if($(this).val()) { values.push(($(this).val())) } 
        })

        // create new row of inputs and focus
        if (($el.attr("id") == "treatment_medicine_remark") && values.length > 0){
          if($("input[name='treatment_medicine[name]']").last().val() != ''){
            $(".add-more-medicine").trigger('click');
          }
          $($row.next('tr').find("input[type='text']:nth-child(1)")[0]).select().focus()
        }else if (values.length == 0){  
          $("#prescription_followup_").select().focus()
        }
        selectOption($el);

      }
      return false;
    }

    if (e.keyCode == 38) { // up
      var selected = $el.parent().next(".custom-select-list").find(".selected");
      $list.find("li").removeClass("selected");
      if (selected.prev().length == 0) {
          selected.siblings().last().addClass("selected");
      } else {
          selected.prev().addClass("selected");
      }
    }

    if (e.keyCode == 40) { // down
      var selected = $el.parent().next(".custom-select-list").find(".selected");
      $list.find("li").removeClass("selected");
      
      if (selected.next().length == 0) {
        selected.siblings().first().addClass("selected");
      } else {
          selected.next().addClass("selected");
      }
    }
  });

  $(document).on("change", "#patient_occupation_id, #patient_city_id, #patient_education_id, #appointment_referrer_id", function(){
    var $el = $(this);
    var selected = $el.find("option:selected").text();
    
    // if "Other" is selected
    if (selected === 'Other') { 
      var input = `<input type="text" id="other-input" class="mb-2 form-control other-input" placeholder="Enter new option">
      <i class="fa fa-plus" id="add-other"></i>
      `;
      
      $el.after(input)
      
      $('#add-other').on('click', function() {
        var newOption = $('#other-input').val();
        if ($el.attr("id") ===  "patient_education_id"){
          var type = "Education"
        }else if ($el.attr("id") ===  "patient_occupation_id"){
          var type = "Occupation"
        }else if ($el.attr("id") ===  "appointment_referrer_id"){
          var type = "Referrer"
        }else if ($el.attr("id") ===  "patient_city_id"){
          var type = "City"
        }
        $.ajax({
          url:  `${HOST}/api/v1/master/create_option?type=${type}&value=${newOption}`,
          headers: { "Authorization": `Token ${TOKEN}` },
          success: function(res){
            $el.append($('<option>', {
              value: res.payload.id,
              text: (res.payload.title || res.payload.full_name  || res.payload.name)
            })).selectpicker('refresh');
            $el.val(res.payload.id).trigger("change")
            $('#add-other, #other-input').remove()
          }
        })
      });
    }else{
      $el.next(".other-input").remove()
      $el.next("#add-other").remove()
    }
  });

  $(".special-inst-code").on("focus", function(){
    $(".special-inst-code").removeClass('border')
    $(this).addClass('border')
  })

  $(document).on("click",".pin-it", function(){
    var block = $(this)
    var appointmentId = block.attr("data-appointmentid")
    $.ajax({
      url:  `${HOST}/api/v1/appointments/${appointmentId}/pin`,
      headers: { "Authorization": `Token ${TOKEN}` },
      method: "PUT",
      success: function(res){
        block.toggleClass("pinned");
      }
    })
  })

  window.sum = function(type){
    let type_total = []; 
        
    $(`td div.${type}`).each(function(a) { 
      type_total.push($(this).text().trim() || 0) 
    })

    // create a variable for the sum and initialize it
    let type_totalSum = 0;

    // Iterate over each item in the array
    for (let i = 0; i <  type_total.length; i++ ) {
      type_totalSum += parseFloat(type_total[i] || 0);
    }
    return type_totalSum.toFixed(2);;
  }

  window.raiseAlertForNonEnoughStock = function(quantityElement, stock_left, tr){
    if (!stock_left && stock_left != 0){ return }
    var stock_left = parseInt(stock_left);
    var quantity = parseInt(quantityElement.val())
    var returned = tr.find("td #returned:checked").length

    if (quantity > stock_left && returned == 0){
      quantityElement.css("border", "1px solid red");
      quantityElement.attr("faulted", "true");
    }else{
      quantityElement.css("border", "none");
      quantityElement.attr("faulted", null);
    }

    var oneStockDefect = tr.parents('table').find("input[faulted=true]").length

    // Return if no quantity is there and no stock defect is there. 
    if (oneStockDefect == 0){
      $('.pharmacy-bill-print').show()
      return 
    }else{
      flashToast(`This much quantity is not available in the stock`, "warning")
      $('.pharmacy-bill-print').hide();
    }
  }

  // $(document).on("change", ".pharmacy_medicine", function(){
  window.fillMedicineMetaInfo = function(el, medicineId){
    if ($(".pharmacy_medicine").length == 0){ return }
    if (!medicineId){
      var medicineId =  el.next().val();
    }
    var type = el.attr("data-classtype");
    if (type == "medicine_salt") { return; }  
    var block = el
    $.ajax({
      url:  `${HOST}/api/v1/medicines/detail?id=${medicineId}`,
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        tr = block.parents('tr')
        let packing = res.payload.packing
        
        tr.find("td .packing").text(packing)
        tr.find("td .stock").text(res.payload.stock_left)
        try {
          var quantity = parseInt(tr.find("td .quantity input").val());
          if (res.payload.stock_left < quantity){
            tr.find("td .stock").addClass('text-danger')
          }else{
            tr.find("td .stock").removeClass('text-danger')
          }
        }catch{ console.log("Stock error") }
        tr.find(".remove-medicine").attr("data-invoice-item-id", medicineId)
        tr.find(".edit-master").attr("href", `/admin/medicines/${medicineId}/edit`)
        
        var quantity = tr.find("td .quantity input")
        // raiseAlertForNonEnoughStock(quantity, res.payload.stock_left, tr)
        // tr.find("td .discount").text(discount)
        // tr.find("td .amount").text(amount)
        // net = amount - discount
        // tr.find("td .net").text(net)

        // $(".total-amount").text( sum("amount") )
        // $(".total-discount").text( sum("discount") )
        // $(".total-net").text( sum("net") )
      }
    })
  }
})

