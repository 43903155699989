import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Quick!")
  }

  fillInvestigations(event){
    if ($(event.target).length == 0){
      var $el = event
    }else{
      var $el = $(event.target)
    }
    var appointmentId = $el.attr("data-appointmentid");

    if (appointmentId){
      $.ajax({
        url: `${HOST}/api/v1/appointments/${appointmentId}`, 
        headers: { "Authorization": `Token ${TOKEN}` },
        success: function(appt){

          // Fill investigation value
          var medParams = appt.payload.quick_investigations
          $(`.investigations`).html(null)
          $(`.investigations`).next('a').addClass("d-none")
          console.log("Medparams", medParams)
          
          for (let index = 0; index < medParams.length; index++) {
            var obj = medParams[index];
            var val = '';

            if (obj && obj.value){ val = obj.value } 
            let fields = `
                <div class="col-3 mt-2 investigation-fields">
                  <label class="display-7"> ${obj.name.toUpperCase()} </label>
                  <div class="d-flex">
                    <div class="small-field">
                      <input type="text" name="value" id="${obj.name.toLowerCase()}" class="form-control" placeholder="${obj.name.toLocaleUpperCase()}" value="${val}">
                      <small class="text-center d-block"></small>
                    </div>
                  </div>
                </div>`;
              $(`.investigations`).append(fields)
              $(`.investigations`).next('a').removeClass("d-none")
          }
          $(`.investigations`).next('a').attr("data-appointmentId", appointmentId)
        },
        error: function(err){
          console.log(err)
          flashToast(err, "warning")
        }
      })
    }
  }

  saveInvestigation(event){
    var $el = $(event.target)
    var data = {}
    $(".investigations input").each(function(){
      data[$(this).attr("id")] = $(this).val()
    })

    $(".save-investigations").addClass("d-none")
    
    $.ajax({ 
      url:`/api/v1/medical_test_reports/investigations`,
      headers: { "Authorization": `Token ${TOKEN}` },
      method: "POST",
      data: {
        reports: data,
        appointment_id: $(`.investigations`).next('a').attr("data-appointmentId"),
        state: "green",
        patient_id: $el.attr(`data-patientid`)
      },
      success: function(res){
        console.log(res)
        for (let index = 0; index < res.payload.length; index++) {
          const element = res.payload[index];
          $(`.investigation-fields #${element.name}`).attr("data-reportid", element.value)
        }
        $(".save-investigations").removeClass("d-none")
        flashToast("Report Value Saved", "info")
        $(`#${$el.attr('data-patientid')}`).addClass('bg-success');
      },
      error: function(err){
        console.log(err)
        flashToast("Test Not Exist", "warning")
      }
    });
  }

  fillPatient(event){
    var block = this;
    var patientId = $(event.target).attr('data-patientId');
    $.ajax({
      url: `${HOST}/api/v1/patients/${patientId}`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        var patientParams = res.payload
        fillPatientData(patientParams)
        $(".appointment-list .card-body").html(table)
        
        var latestAppointment = res.payload.appointments.filter(m => m.status == "paid")[0]
        for (const k in latestAppointment) {
          var apptVal = latestAppointment[k] || "";
          var $apptElement = $(`#appointment_${k}`)
          
          // Appointment Fields
          if(!$($apptElement).is("select")){
            $(`input[name="appointment[${k}]"]`).val(apptVal)
          }else if ($apptElement.is(":checkbox")){
            $(`#appointment_${k}`).prop('checked', apptVal);
          }else if(k.indexOf("_id") == -1){
            $(`button[data-id='appointment_${k}']`).trigger("click")
            $(`button[data-id='appointment_${k}']`)
              .next(".dropdown-menu")
              .find(`span:contains(${ humanize(apptVal) })`)
              .parent().trigger("click")
          }else{
            $(`#appointment_${k}`).val(apptVal).trigger('change')
            // selectDropdownById(`appointment_${k}`, apptVal)
          }

          if (["tobacco", "alcohol", "smoking"].indexOf(k) != -1){
            $(`#appointment_${k}`).prop('checked', apptVal);
          }
        }

        console.log(patientParams)

        $("#appointment_doctor_id").attr("disabled", true)
        $('#appointment_doctor_id').selectpicker('refresh');

        $("#appointment_consultant_id").attr("disabled", true)
        $('#appointment_consultant_id').selectpicker('refresh');
        $('#appointment_visit_date').val(latestAppointment?.visit_date);

        $("#appointment_referrer_id").val(latestAppointment?.referrer_id).trigger("change")

        var slotId = latestAppointment.appointment_slot_id;
        if (slotId){
          $("#appointment_appointment_slot_id").html(`<option selected=true value="${slotId}">
            ${latestAppointment.visit_time}
          </option>`)
          $("#appointment_appointment_slot_id").attr("disabled", true)
        }
      
        const appnts = res.payload.appointments
        var tr = '';

        for (let index = 0; index < appnts.length; index++) {
          const appointment = appnts[index];
          tr += `<tr id="visit-${appointment.id}" class="selected-patient-appointments" data-appointmentid="${appointment.id}" data-action="click->quick#fillInvestigations"> 
            <td class="pl-1"> ${appointment.status} </td> 
            <td> ${appointment.id || ''} </td> 
            <td class="display-12"> 
              <small>${parseDate(appointment.visit_date)}   ${appointment.visit_time || '<i class="badge badge-sm badge-warning">waiting</i>'} </small> 
            </td> 
          </tr>`
        }

        let table = `<table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Status</th>
                <th scope="col">Appointment ID</th>
                <th scope="col display-1">Visit Time</th>
              </tr>
            </thead>
            <tbody> ${tr} </tbody>
          </table>
        `;
        $(".appointment-list").removeClass("d-none")
        $(".appointment-list .card-body").html(table)
        
        var el = $(`#visit-${latestAppointment.id}`)
        $(".investigations").next("a").attr("data-patientid", patientId);
        $(".quick-save").attr("data-appointmentid", latestAppointment.id)
        $(".quick-save").attr("data-patientid", patientParams.id)
        block.fillInvestigations(el)
      }
    })
  }



  // Update Appointment
  saveAppointment(event){

    var appointmentParams = [
      "referrer_id",
      "height",
      "weight",
      "fever",
      "bp_s",
      "bp_d",
      "bmi",
      "tobacco",
      "alcohol",
      "smoking"
    ]

    var appointmentId = $(event.target).attr("data-appointmentid");
    var patientid = $(event.target).attr("data-patientid");
    
    var objAppt = { state: "red" }
    for (let i = 0; i < appointmentParams.length; i++) {
      var element = appointmentParams[i];
      if ($(`#appointment_${element}`).val()) {
        objAppt[element] = $(`#appointment_${element}`).val()
      }else if ( ["tobacco","alcohol","smoking"].indexOf(element) != -1 ){
        objAppt[element] = $(`#appointment_${element}`).prop('checked')
      }
    }

    $.ajax({
      url: `${HOST}/api/v1/appointments/${appointmentId}`,
      headers: { "Authorization": `Token ${TOKEN}` },
      data: { appointment: objAppt },
      method: "PUT",
      success: function(pp){
        console.log(pp)
        const $el = $(".doctor-appt-table").find(`tr[data-appointnmentid='${pp.id}']`)

        if ($el.length > 0){
          $(`#${$el}`).addClass('bg-danger');
        }
        flashToast("Successfully Saved", "info")
      },
      error: function(err, obj){
        try {
          var msg = err.responseJSON.status.message
        }catch {
          var msg = "Failed. Please fill correct appointment data"
        }
        flashToast(msg, "warning");
      } 
    })


    $.ajax({
      url: `${HOST}/api/v1/patients/${patientid}`,
      headers: { "Authorization": `Token ${TOKEN}` },
      data: { patient: {
        full_name: $("#patient_full_name").val(),
        diet: $("#patient_diet").val()
      } },
      method: "PUT",
      success: function(res){
        console.log(res)
        flashToast("Updated Successfully", "info")
      },
      error: function(err){
        console.log(err)
        flashToast(err.responseJSON.status.message, "warning")
      } 
    })

    return false;
  };


  referrerForm(event){
    console.log(event)
    var referrerId = $(event.target).closest("a").attr("data-referrer_id");

    if (!referrerId){
      referrerId = $("#appointment_referrer_id").val() 
    }
    $.get(`/quick_check/referrer_form?referrer_id=${referrerId}`)
  }

}