require("bootstrap")
require("bootstrap-select")
require("jquery-typeahead")

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import $ from 'jquery';
import 'bootstrap';
import 'popper.js/dist/popper.js';
import 'select2';
import Toastify from 'toastify-js'
window.Toastify = Toastify

Rails.start()
ActiveStorage.start()

window.jQuery = $;
window.$ = $;
window.Rails = Rails;
window.HOST = window.location.origin;

require("controllers")
require('../custom/leaflet.js')
require('../custom/main.js')
require('../custom/script.js')

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
  $(".form-select").select2()
})

window.flashToast = function(msg, type, action = function(){}){
  if (type == "info"){
    var color = "linear-gradient(to right, #00b09b, #96c93d)"
  }else if (type == "warning"){
    var color = "linear-gradient(to right, #cf5050, #ff6f07)"
  }

  Toastify({
    text: msg,
    className: type,
    duration: 5000,
    style: {
      background: color,
    },
    onClick: function () {
      toast.hideToast();
    },
    callback: action
  }).showToast();
}

window.readCookie = function(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

// Toastify({
//   text: "This is a toast",
//   duration: 3000,
//   destination: "https://github.com/apvarun/toastify-js",
//   newWindow: true,
//   close: true,
//   gravity: "top", // `top` or `bottom`
//   position: "left", // `left`, `center` or `right`
//   stopOnFocus: true, // Prevents dismissing of toast on hover
//   style: {
//     background: "linear-gradient(to right, #00b09b, #96c93d)",
//   },
//   onClick: function(){} // Callback after click
// }).showToast();