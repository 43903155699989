import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Hello Auth")
  }


  login(event){
    var dataset = $(event.target)
    var data = {
      account: {
        uid: dataset.find("input[name='uid']").val().trim(),
        password: dataset.find("input[name='password']").val().trim()
      }
    }

    $.ajax({
      url: "/api/v1/accounts/login",
      method: "POST",
      data: data,
      success: function(res){
        var token = res.payload.token
        document.cookie = `_priac_jwt=${token}; max-age=Sun, 1 Jan 2123 00:00:00 UTC;`
        window.location.href = res.payload.redirect
      },
      error: function(res){
        console.log(res)
        flashToast("Invalid Credentials", "warning")
      }
    })
  }

}