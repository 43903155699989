import { Controller } from "@hotwired/stimulus"
import { event } from "jquery"

export default class extends Controller {
  connect() {
    console.log("Billing!")
  }

  remove(event){
    var el = $(event.target)
    let id = el.closest("button").attr("data-type")
    let totalDiscount = parseFloat($(`#${id}`).find("#discount").text()) 
    let discount = el.parents(".test-row").find("#medical_test_discount").val()
    $(`#${id}`).find("#discount").text(totalDiscount - parseFloat(discount))
    this.calculate($(event.target), id)
    this.grandTotal()

    var itemId = el.closest("button").attr("data-invoice_item_id");

    $.ajax({
      url: `${HOST}/api/v1/invoices/cancel_test?item_id=${itemId}`,
      method: "DELETE",
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        console.log(res)
        el.parents(".test-row").remove()
        flashToast("Test Removed", "success")
      },
      error: function(err){
        console.log(err)
        flashToast(err, "warning")
      }
    })
  }

  grandTotal(){
    let total = parseFloat($(`#x-ray_total`).val() || 0) + 
                parseFloat($(`#lab_total`).val() || 0) +
                parseFloat($(`#dietician_total`).val() || 0) +
                parseFloat($(`#physiotherapist_total`).val() || 0) +
                parseFloat($(`#psychologist_total`).val() || 0)

    $("#billing_grand_total").val(total)
  }

  calculate(el, id){
    if (id == "lab"){ 
      var className = ".lab-tests"
    }

    if (id == "x-ray"){
      var className = ".x-rays"
    }

    var tests = $(className).find("input[name='medical_test[amount]']")
    var amount = 0;
    var discountV = 0;
    for (let index = 0; index < tests.length; index++) {
      const test = tests[index];
      let entry =  parseInt($(test).val());
      let dis = parseInt($(test).next().val())
      if (!isNaN(entry)){
        amount += entry 
      }
      if (!isNaN(dis)){
        discountV += dis 
      }
    }
    let net = amount - discountV
    $(`#${id}_total`).val(net)
  }

  fillItemsAndCharges(event) {
    let medicalTestId = $(event.target).val();
    var ctx = this;
    $.ajax({
      url: `${HOST}/billing/add_lab_test?type=procedure&medical_test_id=${medicalTestId}`,
      success: function(res){
        ctx.fillCharges(event)
      }
    })
  }

  updateCharges(event){
    var total = 0; 
    // var quantity = $(event.target).val()
    // var amount = $(event.target).next().val()
    // if (quantity && amount ){
    //   var total = quantity * amount
    //   $(event.target).next().val(total)
    // }
    $(".procedure .test-row").each(function(el){
      var quantity = $(this).find("input[name='medical_test[quantity]']").val();
      var amount =   $(this).find("input[name='medical_test[amount]']").val();
      var discount = $(this).find("input[name='medical_test[discount]']").val();
      
      if (quantity && amount){
        if (discount){
          total += (parseFloat(quantity) * parseFloat(amount)) * 0.01 * parseFloat(discount)
        }else{
          total += (parseFloat(quantity) * parseFloat(amount))
        }
      }
    })
    $("#daycare_total").val(total.toFixed(2))
  }

  // Triggered on test dropdown change
  fillCharges(event){
    let medicalTestId = $(event.target).val();
    var $el = $(event.target);
    var ctx = this 
    $.ajax({
      url: `${HOST}/api/v1/medical_tests/${medicalTestId}`,
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        console.log(res)
        if (res.payload.id){
          let val = res.payload.lab_id
          let num = isNaN(parseInt(val)) ? 0 : parseInt(val)
          $el.parents(".test-row").find(`button[data-id='medical_test_lab_id']`).trigger("click")
          $el.parents(".test-row").find(`button[data-id='medical_test_lab_id']`)
            .next(".dropdown-menu")
            .find(`ul li:nth-child(${ num +1}) a`)
            .trigger("click")
          $el.parents(".test-row").find("#medical_test_amount").val(res.payload.amount)
          $el.parents(".test-row").find("#medical_test_discount").val(res.payload.discount)
          var dis = $el.closest(".department-name").find("#discount").text()
          var discount = parseFloat(dis) + res.payload.discount
          $el.closest(".department-name").find("#discount").text(discount)

          // Calculate the section wise total
          let id = $el.closest(".department-name").attr("id")
          ctx.calculate($el, id)

          // Calculate the grand total
          ctx.grandTotal()
        }
      },
      error: function(){}
    })
  }

  getPlans(event){
    var id = $(event.target).val()
    var name = $(event.target).attr("id")
    var type = name.split("_")[0]
    $.ajax({
      url: `${HOST}/api/v1/specialists/${id}/get_plans`,
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        console.log(res)
        // Start selecting the dropdowns
        var options = '';
        for (let index = 0; index < res.payload.length; index++) {
          let plan = res.payload[index]
          options += `<option 
            data-amount='${plan.amount}' 
            data-discount='${plan.discount}' 
            value="${plan.id}">${plan.title}</option>`
        }
        $(`#${type}_plan`).html(options).selectpicker('refresh')
      }
    })
  }



  printDaycare(event){
    // Daycare fields
    var room = $("#room_no").val()
    var bed_no = $("#bed_no").val()
    var checkin = $("#checkIn").val()
    var checkout = $("#checkout").val()
    var admission_id = $("#daycare .save_print").attr("data-admission_id")
    var patientId = $(".update-patient-info").attr("data-patientid")
    var context = this;
    
    if (admission_id){
      var method = "PUT";
    }else{
      var method = "POST";
    }

    if (room){
      $.ajax({
        url: `${HOST}/api/v1/admissions/${admission_id || ''}`,
        method: method,
        data: {
          admission: {
            room_no:  room,
            bed_no:  bed_no,
            checkin: checkin,
            checkout: checkout,
            patient_id: patientId
          }
        },
        headers: { "Authorization": `Token ${TOKEN}` },
        success: function(res){
          if (res.payload.id){
            let admissionId = res.payload.id
            context.prepareProcedureTestsData(event, admissionId)
            flashToast("Done", "info")
          }
        },
        error: function(err){
          flashToast(err, "warning")
        }
      })
    }else{
      context.prepareProcedureTestsData(event)
    }
    return false;
  }
  

  prepareProcedureTestsData(event, admissionId=null){
    var data = {}
    var $parent = $(event.target).parents(".department-name")
    var departmentId = $parent.attr('data-department_id')
    var old_invoice_id = $parent.find(".save_print").attr("data-invoice_id")
    var invoice_type = $parent.attr("data-invoice_type")
    var tests = []
    var quantityPresent = true;

    
    $(".procedure input[name='medical_test[quantity]']").each(function(){
      let qty = $(this).val()
      let item = $($(this).parents(".row")[0]).find("select.medical_test_id").val()
      if (!item){ return; }
      if (qty && qty!= 0){
        tests.push({
          id: item,
          quantity: parseInt(qty) 
        })
      }else{
        quantityPresent = false
        flashToast("Quantity is not selected", "warning")
        return;
      }
    })

    if (!quantityPresent) { return; }
    
    if (tests.length != 0){
      data["tests"] = [{
        invoice_type: invoice_type,
        old_invoice_id: old_invoice_id,
        department_id: departmentId, 
        test_ids: tests
      }]
    }

    var uid = $("input[name='patient[uid]']").val()
    var full_name = $("input[name='patient[full_name]']").val()
    var mobile = $("input[name='patient[mobile]']").val()
    var dob = $("input[name='patient[dob]']").val()
    var gender = $("select[name='patient[gender]']").val()

    data["mobile"] = mobile
    data["name"] = full_name
    data["dob"] = dob
    data["gender"] = gender
    if (uid){
      data["uid"] = uid
    }

    if (!mobile || !full_name || !gender){
      flashToast("Please fill Mobile, Gender, DOB & Full Name", "warning")
      return 
    }

    if (admissionId){
      data['admissionId'] = admissionId
    }
    console.log(data)
    window.generateTestsInvoice(null, data)
  }

  prepareTestsData(event, admissionId=null){
    var data = {}
    var test_ids = []
    var $parent = $(event.target).parents(".department-name")
    var departmentId = $parent.attr('data-department_id')
    var old_invoice_id = $parent.find(".save_print").attr("data-invoice_id")
    var tests = $parent.find("select.medical_test_id")
    for (let index = 0; index < tests.length; index++) {
      if (tests[index].value){
        test_ids.push(tests[index].value)
      }
    }
    if (test_ids.length != 0){
      data["tests"] = [{
        invoice_type: $parent.attr("data-invoice_type"),
        old_invoice_id: old_invoice_id,
        department_id: departmentId, 
        test_ids: test_ids
      }]
    }
  
    var appointment_id = $(".save_print").attr('data-appointmentId')
    var uid = $("input[name='patient[uid]']").val()
    var full_name = $("input[name='patient[full_name]']").val()
    var mobile = $("input[name='patient[mobile]']").val()
    var dob = $("input[name='patient[dob]']").val()
    var gender = $("select[name='patient[gender]']").val()

    data["mobile"] = mobile
    data["name"] = full_name
    data["dob"] = dob
    data["gender"] = gender
    if (uid){
      data["uid"] = uid
    }

    if (!mobile || !full_name || !gender){
      flashToast("Please fill Mobile, Gender, DOB & Full Name", "warning")
      return 
    }

    if (admissionId){
      data['admissionId'] = admissionId
    }
    console.log(data)

    if (!appointment_id) { 
      window.generateTestsInvoice(null, data)
    }else{
      window.generateTestsInvoice(appointment_id, data)
    }

  }

  printTests(event){
    if ($(event.target).attr("id") == "print_all"){
      var data = {}
      data["tests"] = [] 
      // var tests = $("select.medical_test_id")
      $(".department-name").each(function(){
        var test_ids = []
        var old_invoice_id = $(this).find(".save_print").attr("data-invoice_id")
        var departmentId = $(this).attr('data-department_id')
        var tests = $(this).find("select.medical_test_id")
        for (let index = 0; index < tests.length; index++) {
          if (tests[index].value){
            test_ids.push(tests[index].value)
          }
        }

        if (test_ids.length != 0){
          data["tests"].push({
            invoice_type: $(this).attr("data-invoice_type"),
            department_id: departmentId, 
            old_invoice_id: old_invoice_id,
            test_ids: test_ids
          })
        }
      })
    }else{
      this.prepareTestsData(event)
      return false;
    }
  }

  fillTests(event){
    let appointment_id = $(event.target).closest('tr').attr("data-appointment_id") 
    
    $.ajax({
      url: `${HOST}/api/v1/appointments/${appointment_id}/medical_tests`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        // Fill Tests Area
        var tests = res.payload.medical_tests;
        console.log(tests)
        
        var medical_tests = tests.filter( a => 
          (a.category == "PATHOLOGY" || a.category == "OUTSOURCE"  || a.category == "LAB" ) 
        )

        if (medical_tests.length > 0){
          $("#lab").collapse('show')
          $.ajax({
            url: `${HOST}/billing/add_lab_test?type=lab`,
            data: {
              medical_tests: medical_tests
            },
            method: "POST",
            headers: { "Authorization": `Token ${TOKEN}` },
            success: function(res){
              // Start selecting the dropdowns
              var amount = 0;
              var discount = 0;
              for (let index = 0; index < medical_tests.length; index++) {
                let test = medical_tests[index]
                amount += parseInt(test.amount);
                discount += test.discount
              }
              let net = amount - discount
              $(`#lab_total`).val(net)
              $("#lab").find("#discount").text(discount)
            }
          })
        }

        var xrays = tests.filter( a => a.category == "RADIOLOGY" )
        if (xrays.length> 0){
          $("#x-ray").collapse('show')
          $.ajax({
            url: `${HOST}/billing/add_xray`,
            data: {
              medical_tests: xrays
            },
            method: "POST",
            headers: { "Authorization": `Token ${TOKEN}` },
            success: function(res){
              // Start selecting the dropdowns
              var amount = 0;
              var discount = 0;
              for (let index = 0; index < xrays.length; index++) {
                let radio = xrays[index]
                amount += parseInt(radio.amount);
                discount += radio.discount
              }

              let net = amount - discount
              $(`#x-ray_total`).val(net)
              $("#x-ray").find("#discount").text(discount)
              
              // Update grand total. 
              // NOTE: This calculation should be at last only
              let total = parseFloat($(`#x-ray_total`).val() || 0) + parseFloat($(`#lab_total`).val() || 0)

              $("#billing_grand_total").val(total)
            }
          })
        }
        
        // $(`.save_print`).attr('data-appointmentId', appointment_id);
      }
    })
  }

  refund(event){
    event.preventDefault()
    var invoice_id = $(event.target).attr('data-invoice_id')
    $.ajax({
      url: `${HOST}/api/v1/invoices/${invoice_id}/refund`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      method: 'PUT',
      success: function(res){
        console.log(res)
        // $(`#invoice-${invoice_id}`).remove();
        window.location.reload()
        flashToast("Done", "info")
      },
      error: function(err){
        console.log(err)
        flashToast(`${err.responseJSON.status.message}`, "warning")
      }
    })
  }

  reprint(event){
    var invoiceId = $(event.target).attr("data-invoice_id")
    flashToast("Reprinting Invoice...", "info")

    // Send request to server to generate invoice
    $.ajax({
      url: `${HOST}/api/v1/invoices/${invoiceId}/reprint`, 
      method: "GET",
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(inv){
        console.log(inv)
        if (inv.payload.id){
          printPage(inv.payload.content);
        }else{
          flashToast("Something Happened", "warning")
        }
      },
      error: function(err){
        flashToast(`${err}`, "warning")
      }
    })
  }

  refreshTests(){
    $.ajax({
      url: `${HOST}/billing/reload_departments_invoices`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
      }
    })
  }

  loadInvoiceData(event){
    var $el = $(event.target).closest('td')
    var invoice_ids = $el.attr("data-invoice_ids").split(",")
    var context = this
    this.refreshTests()
    setTimeout(function(){ context.departmentInvoiceFlaps($el, invoice_ids) }, 500);
  }

  departmentInvoiceFlaps(el, invoice_ids){
    for (let index = 0; index < invoice_ids.length; index++) {
      var invoice_id = invoice_ids[index];
      $.ajax({
        url: `${HOST}/api/v1/invoices/${invoice_id}`, 
        headers: { "Authorization": `Token ${TOKEN}` },
        success: function(invoice){
          // $("#print_all").remove()
          // $("#patient_uid").val(res.payload.patient_uid)
          
          if (invoice.payload.patient_id){
            getPatientAndFillData(invoice.payload.patient_id)
            $(".update-patient-info").attr('data-patientId', invoice.payload.patient_id)
          }else{
            $("#patient_full_name").val(invoice.payload.name)
            $("#patient_mobile").val(invoice.payload.mobile)
          }

          // Fill Tests Area
          var tests = invoice.payload.invoice_items;
          console.log(invoice)

          var medical_tests = tests.filter( a => 
            (a.item.category == "PATHOLOGY" || a.item.category == "OUTSOURCE"  || a.item.category == "LAB" ) 
          )
          var procedures = tests.filter( a => a.item.category == "PROCEDURE" || a.item.category == "ADMISSION"  )
          var psychologist = tests.filter( a => a.item.category == "psychologist"  )
          var physiotherapist = tests.filter( a => a.item.category == "physiotherapist"  )
          var dietician = tests.filter( a => a.item.category == "dietician"  )
          var rheumatology = tests.filter( a => a.item.category == "doctor"  )

          if (physiotherapist.length > 0){
            $(`#physiotherapist`).collapse('show')
            let entry = physiotherapist[0].item

            let $ph = $(`#physiotherapist`)
            $ph.find('#physiotherapist_name').val(entry.specialist_id).trigger("change")
            $ph.find('#physiotherapist_plan').val(entry.plan_id).trigger("change")
            $ph.find(`#physiotherapist_total`).val(physiotherapist[0].amount)
            $ph.find("#discount").text(entry.discount)
            $ph.find('.test-refund').removeClass('d-none')
            $ph.find(".test-refund").attr("data-invoice_id", invoice.payload.id)
            $ph.find(".save_print").attr("data-invoice_id", invoice.payload.id)
            $ph.find(".test-reprint").attr("data-invoice_id", invoice.payload.id).removeClass("d-none").addClass("d-block")
          }

          if (rheumatology.length > 0){
            $(`#rheumatology`).collapse('show')
          }

          if (psychologist.length > 0){
            $(`#psychologist`).collapse('show')
            let entry = psychologist[0].item

            let $ps = $(`#psychologist`)
            $ps.find('#psychologist_name').val(entry.specialist_id).trigger("change")
            $ps.find('#psychologist_plan').val(entry.plan_id).trigger("change")
            $ps.find(`#psychologist_total`).val(psychologist[0].amount)
            $ps.find("#discount").text(entry.discount)
            $ps.find('.test-refund').removeClass('d-none')
            $ps.find(".test-refund").attr("data-invoice_id", invoice.payload.id)
            $ps.find(".save_print").attr("data-invoice_id", invoice.payload.id)
            $ps.find(".test-reprint").attr("data-invoice_id", invoice.payload.id).removeClass("d-none").addClass("d-block")
          }

          if (dietician.length > 0){
            $(`#dietician`).collapse('show')
            let entry = dietician[0].item

            let $dt = $(`#dietician`)
            $dt.find('#dietician_name').val(entry.specialist_id).trigger("change")
            $dt.find('#dietician_plan').val(entry.plan_id).trigger("change")
            $dt.find(`#dietician_total`).val(dietician[0].amount)
            $dt.find("#discount").text(entry.discount)
            $dt.find('.test-refund').removeClass('d-none')
            $dt.find(".test-refund").attr("data-invoice_id", invoice.payload.id)
            $dt.find(".save_print").attr("data-invoice_id", invoice.payload.id)
            $dt.find(".test-reprint").attr("data-invoice_id", invoice.payload.id).removeClass("d-none").addClass("d-block")
          }

          if (medical_tests.length > 0){
            $("#lab").collapse('show')
            $.ajax({
              url: `${HOST}/billing/add_lab_test?type=lab`,
              data: {
                medical_tests: medical_tests,
                invoice: true
              },
              method: "POST",
              headers: { "Authorization": `Token ${TOKEN}` },
              success: function(res){
                // Start selecting the dropdowns
                var amount = 0;
                var discount = 0;
                for (let index = 0; index < medical_tests.length; index++) {
                  let invoice = medical_tests[index]
                  amount += parseInt(invoice.amount);
                  discount += invoice.item.discount
                }
                let net = amount - discount
                $(`#lab_total`).val(net)
                $("#lab").find("#discount").text(discount)
                $("#lab").find('.test-refund').removeClass('d-none')
                $("#lab").find(".test-refund").attr("data-invoice_id", invoice.payload.id)
                $("#lab").find(".save_print").attr("data-invoice_id", invoice.payload.id)
                $("#lab").find(".test-reprint").attr("data-invoice_id", invoice.payload.id).removeClass("d-none").addClass("d-block")
                
              }
            })
          }

          if (procedures.length > 0){
            $("#daycare").collapse('show')

            $.ajax({
              url: `${HOST}/billing/add_lab_test?type=procedure`,
              data: {
                medical_tests: procedures,
                invoice: true
              },
              method: "POST",
              headers: { "Authorization": `Token ${TOKEN}` },
              success: function(res){
                // Start selecting the dropdowns
                var amount = 0;
                var discount = 0;                
                for (let index = 0; index < procedures.length; index++) {
                  let invoice = procedures[index]
                  amount += parseInt(invoice.amount);
                  discount += invoice.item.discount
                }

                let net = amount - discount

                $("#daycare #room_no").val(invoice.payload.admission?.room_no)
                $("#daycare #bed_no").val(invoice.payload.admission?.bed_no)
                if (invoice.payload.admission?.checkin){
                  $("#daycare #checkIn").val(pickerFormatDate(invoice.payload.admission?.checkin)).trigger('change')
                } 
                if (invoice.payload.admission?.checkout){
                  $("#daycare #checkout").val(pickerFormatDate(invoice.payload.admission?.checkout)).trigger('change')
                }

                $(`#daycare_total`).val(net)
                $("#daycare").find("#discount").text(discount)
                $("#daycare").find('.test-refund').removeClass('d-none')
                $("#daycare").find(".test-refund").attr("data-invoice_id", invoice.payload.id)
                $("#daycare").find(".save_print").attr("data-invoice_id", invoice.payload.id)
                $("#daycare").find(".save_print").attr("data-admission_id", invoice.payload.admission?.id)
                $("#daycare").find(".test-reprint").attr("data-invoice_id", invoice.payload.id).removeClass("d-none").addClass("d-block") 
              }
            })
          }

          var xrays = tests.filter( a => a.item.category == "RADIOLOGY"  )

          if (xrays.length> 0){
            $("#x-ray").collapse('show')
            $.ajax({
              url: `${HOST}/billing/add_xray`,
              data: {
                medical_tests: xrays,
                invoice: true
              },
              method: "POST",
              headers: { "Authorization": `Token ${TOKEN}` },
              success: function(res){
                // Start selecting the dropdowns
                var amount = 0;
                var discount = 0;
                for (let index = 0; index < xrays.length; index++) {
                  let invoice = xrays[index]
                  amount += parseInt(invoice.amount);
                  discount += invoice.item.discount
                }

                let net = amount - discount
                $(`#x-ray_total`).val(net)
                $("#x-ray").find("#discount").text(discount)
                $("#x-ray").find('.test-refund').removeClass('d-none')
                $("#x-ray").find(".test-refund").attr("data-invoice_id", invoice.payload.id)
                $("#x-ray").find(".save_print").attr("data-invoice_id", invoice.payload.id)
                $("#x-ray").find(".test-reprint").attr("data-invoice_id", invoice.payload.id).removeClass("d-none").addClass("d-block")
                
                // Update grand total. 
                // NOTE: This calculation should be at last only
                let total = parseFloat($(`#x-ray_total`).val() || 0) + parseFloat($(`#lab_total`).val() || 0)

                $("#billing_grand_total").val(total)
              }
            })
          }
          
          $(`.save_print`).attr('data-appointmentId', $(el).data("appointment_id"));
        }
      })
    }
  }

  fillPatient(event){
    var $el = $(event.target)
    let patientId = $el.attr("data-patientid")
    
    // Fill patient and appointment form
    $.ajax({
      url: `${HOST}/api/v1/patients/${ patientId }`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        console.log(res)
        var patientParams = res.payload

        // Clean up reprint button
        $(`.reprint-consultancy-invoice`).addClass("d-none")
        $(`.reprint-consultancy-invoice`).attr("data-invoice_id", null)

        fillPatientData(patientParams)

        // Clean up the past appointments table
        $(".appointment-list").addClass("d-none")
        $(".appointment-list .card-body").html(null)

        // Set today's date on page load
        var today = new Date();
        $("#appointment_visit_date").val(today.toISOString().slice(0, 10))

        // Get all the appointments that are not cancelled or rescheduled 
        const appnts = res.payload.appointments.filter(m => 
          (m.status != "cancelled") && (m.status != "rescheduled")
        )
        if ($(".appointment-list").length != 0 && appnts.length > 0){
          var latestAppointment = appnts[0]
          var tr = '';

          // Fill appointment info
          $("#appointment_consultant_id").val(latestAppointment.consultant_id).trigger("change")
          $("#appointment_doctor_id").val(latestAppointment.doctor_id).trigger("change")
           
          // Check if the patient has appointment today
          var appt_visit_date = latestAppointment.visit_date.replaceAll("-", "/")
          var today_date = new Date().toJSON().slice(0,10).replace(/-/g,'/')
          
          if (appt_visit_date == today_date){
            $(".generate-invoice").attr("data-appointmentId", latestAppointment.id)
            $(`.departments-invoices .save_print`).attr('data-appointmentId', latestAppointment.id);
          }

          // Disable the visit type if it has value
          if (patientParams.appointments[0]){
            $("#appointment_visit_type").val(patientParams.appointments[0].visit_type).trigger("change");
          }

          // Creating date objects without time component
          var visitDate = new Date(latestAppointment.visit_date);
          var currentDate = new Date();

          visitDate.setHours(0, 0, 0, 0);
          currentDate.setHours(0, 0, 0, 0);

          // Nullify the time if loaded patient has future appointment and came today
          if (visitDate.getTime() == currentDate.getTime()) {
            var options = $("#appointment_appointment_slot_id option")
            $("#appointment_appointment_slot_id").html(`
                <option value="${latestAppointment.appointment_slot_id}">
                  ${latestAppointment.visit_time}
                </option>
            ` + options)
            $("#appointment_appointment_slot_id").val(latestAppointment.appointment_slot_id).attr("disabled", true)
          }else{
            $("#appointment_appointment_slot_id").val(null)
            $("#appointment_appointment_slot_id").removeAttr("disabled")
          }

          // Enable reprinting
          if (patientParams.invoice_id){
            $(`.reprint-consultancy-invoice`).removeClass("d-none")
            $(`.reprint-consultancy-invoice`).attr("data-invoice_id", patientParams.invoice_id)
            $(".generate-invoice").addClass("d-none")
          }

          for (let index = 0; index < appnts.length; index++) {
            const appointment = appnts[index];
            
            tr += `<tr id="" class="selected-patient-appointments" data-appointment_id="${appointment.id}" data-action="click->billing#fillTests"> 
              <td class="pl-1"> ${appointment.status} </td> 
              <td class="display-12"> 
                <small>${parseDate(appointment.visit_date)}   ${appointment.visit_time || '<i class="badge badge-sm badge-warning">waiting</i>'} </small> 
                </td> 
              
            </tr>`
          }
    
          let table = `<table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Status</th>
                  <th scope="col display-1">Visit Time</th>
                </tr>
              </thead>
              <tbody> ${tr} </tbody>
            </table>
          `;
          $(".appointment-list").removeClass("d-none")
          $(".appointment-list .card-body").html(table)
        }else{
          $("#appointment_appointment_slot_id").val(null)
          $("#appointment_appointment_slot_id").removeAttr("disabled")
        }
      }
    })
    $(".update-patient-info").attr('data-patientId', patientId)

    // Enter Amount
    $("#billing_grand_total").val(700)

    // Clean up the tests
    this.refreshTests()
  }

  consultingInvoice(event){
    event.preventDefault()

    var apptId = $(event.target).attr("data-appointmentId")
    var form = $(event.target).parents("form")
    if (apptId){
      var data = {
        visit_type: form.find("#appointment_visit_type").val(),
        doctor_id: form.find("#appointment_doctor_id").val(),
        visit_date: form.find("#appointment_visit_date").val(),
        appointment_slot_id: form.find("#appointment_appointment_slot_id").val(),
        consultant_id: form.find("#appointment_consultant_id").val(),
      }
      updateAppointmentAndGenerateInvoice(data, apptId)
    }else{
      takeAppointment()
    }
  }

  fillSessionPrice(event){
    var id = $(event.target).parents(".department-name").attr("id")
    let amount = $(event.target).find("option:selected").attr("data-amount")

    // There is no discount on sessions so this line is not used
    let discount = $(event.target).find("option:selected").attr("data-discount")
    $(event.target).parents(".department-name").find(`#${id}_total`).val(amount)
    this.grandTotal()
  }
}