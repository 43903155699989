import { application } from "./application"

import PatientController from "./patient_controller"
application.register("patient", PatientController)

import PrescriptionController from "./prescription_controller"
application.register("prescription", PrescriptionController)

import BillingController from "./billing_controller"
application.register("billing", BillingController)

import AppointmentController from "./appointment_controller"
application.register("appointment", AppointmentController)

import LabController from "./lab_controller"
application.register("lab", LabController)

import AuthController from "./auth_controller"
application.register("auth", AuthController)

import QuickController from "./quick_controller"
application.register("quick", QuickController)

import PharmacyController from "./pharmacy_controller"
application.register("pharmacy", PharmacyController)

