import { Controller } from "@hotwired/stimulus"
import { event } from "jquery"

export default class extends Controller {
  connect() {
    console.log("Appointment!")
  }

  getSlot(event){
    var date = $(event.target).attr('data-date');
    var slotId = $(event.target).attr('data-slotid');
    $("#appointment_appointment_slot_id").html(`<option selected=true value="${slotId}">
      ${$(event.target).text()}
    </option>`)
    $("#appointment_visit_date").val(date)
  }

  fillPatient(event){
    try {
      var el = $(event.target)
      var patientId = el.attr("data-patientId")
    }catch{
      var patientId = event
    }
    // Set PatientId to update button
    $(".update-patient-info").attr('data-patientId', patientId)
    $("#patient-search-results").hide();
    
    $.ajax({
      url: `${HOST}/api/v1/patients/${ patientId }`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        console.log(res)
        var patientParams = res.payload
        fillPatientData(patientParams)
        
        // Clean up the past appointments table
        $(".appointment-list").addClass("d-none")
        $(".appointment-list .card-body").html(null)

        if ($(".appointment-list").length != 0){
          const appnts = res.payload.appointments.sort((a,b)=>{
            if (parseInt(a.uid) < parseInt(b.uid)) return 1;
            if (parseInt(a.uid) > parseInt(b.uid)) return -1;
          })

          var tr = '';
    
          for (let index = 0; index < appnts.length; index++) {
            const appointment = appnts[index];
            if (appointment.status == 'pending' || appointment.status == 'confirmed'){
              var cancel_button = `<a href="#" data-remote="true" class="btn btn-danger btn-sm btn-square rounded-pill" data-appointmentId="${appointment.id}" data-action="ajax:success->appointment#cancel" data-confirm="Are you Sure?">
                <i class="fa fa-times" aria-hidden="true"></i>
              </a>`;
            }else{
              var cancel_button = '';
            }

            tr += `<tr id="" class="selected-patient-appointments" data-appointment_id="${appointment.id}" data-action="click->billing#fillTests"> 
              <td class="pl-1"> ${appointment.status} </td> 
              <td> ${appointment.uid || ''} </td> 
              <td class="display-12"> 
                <small>${appointment.visit_date}   ${appointment.visit_time || '<i class="badge badge-sm badge-warning">waiting</i>'} </small> 
              </td> 
              <td> 
                ${cancel_button}
              </td> 
            </tr>`
          }
    
          let table = `<table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Status</th>
                  <th scope="col">Visit ID</th>
                  <th scope="col display-1">Visit Time</th>
                  <th scope="col display-1">Cancel</th>
                </tr>
              </thead>
              <tbody> ${tr} </tbody>
            </table>
          `;
          $(".appointment-list").removeClass("d-none")
          $(".appointment-list .card-body").html(table)
        }
      }
    })
    
    return false;
  }

  refresh(patientId){
    // Refresh central area
    this.availableSlots()

    // Refresh visits
    this.filterVisits($('#from_date'))

    // refresh appointments
    this.fillPatient(patientId)
  }

  availableSlots(event){
    try {
      var date = $(event.target).parents('h5').find("span").text()
      var id = $(event.target).attr("id")
    }catch {
      var date = null;
      var id = "next"
    }
    $.ajax({
      url:  `${HOST}/api/v1/appointments/available_slots_3_days?date=${date}&cursor=${ id }`,
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        var column = '';
        for (const date in res.payload.slots) {
          if (Object.hasOwnProperty.call(res.payload.slots, date)) {
            var slots = res.payload.slots[date];

            var slotsStr = '';
            for (let index = 0; index < slots.length; index++) {
              slotsStr += 
                  `<span class="d-block badge mb-3 badge-light">
                    ${slots[index].time || ''}
                  </span>`;
            }

            column += `<div class="appt-column border-right col-xs-4">
                            <p class="pl-2"> ${date} </p>
                            ${slotsStr}
                          </div>`;
          }
        }
        $("#central-date").text(res.payload.current_date);
        $('.available-slots').html(`<div class="row justify-content-center">${column}</div>`);
      },
      error: function(){}
    })
  }

  filterVisits(event){
    try {
      var $el = $(event.target)
    }catch { 
      var $el = event
    }
    var doctor_id = $el.attr('data-doctor-id')
    var status = $(`#doctor-${doctor_id}`).next(".doctor-appointments").find("select[name='appt_status']").val()
    var from_date = $(`#doctor-${doctor_id} #from_date`).val()
    var to_date = $(`#doctor-${doctor_id} #to_date`).val()

    $.ajax({
      url: `${HOST}/appointments/filter_visits?q[visit_date_eq]=${from_date}&q[status_eq]=${status}&q[visit_date_lteq]=${to_date}&doctor_id=${doctor_id}&per_page=100`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){},
      error: function(){}
    });
  }

  createReminder(event){
    $.ajax({
      url: "/api/v1/reminders",
      headers: { "Authorization": `Token ${TOKEN}` },
      method: "POST",
      data: $(event.target).serialize(),
      success: function(res){
        var reminder = res.payload
        if (reminder.id != null){
          $(".modal").modal('hide');
          flashToast("Reminder Saved", "info")
        }
      },
      error: function(res){
        flashToast("Unable to save. Something Happened", "error")
      }
    })
  }

  delete(){
    var date = $(event.target).val();
    $.ajax({
      url: `${HOST}/api/v1/appointments/delete`,
      headers: { "Authorization": `Token ${TOKEN}` },
    }, function(res){
      
    })
  }

  cancel(event){
    var $el = $(event.target).parents("td").find("a")
    var appointmentId = $el.attr('data-appointmentId');
    $.ajax({
      url: `${HOST}/api/v1/appointments/${appointmentId}/cancel`,
      headers: { "Authorization": `Token ${TOKEN}` },
      method: "put",
      success: function(res){
        console.log(res)
        $el.parents("tr").find("td:first-child").text(res.payload.status)
        $el.remove() 
      },
      error: function(err, obj){
        console.log(err)
      }
    })
  }

  //#### don't change anything ####
  // Below API is working but intented to use in future
  filterVisitsUsingAPI(event){
    var $el = $(event.target)
    var status = $el.val() 
    var from_date = $("#from_date").val()
    var to_date = $("#to_date").val()
    var doctor_id = $el.attr('data-doctor-id')

    // Render visits
    $.get(`${HOST}/api/v1/appointments/?q[visit_date_eq]=${from_date}&q[status_eq]=${status}&q[visit_date_lteq]=${to_date}&doctor_id=${doctor_id}&per_page=100`, function(res){
      console.log(res)
      
      var data = res.payload.appointments;
      var appointment_row = '';
      
      for (let index = 0; index < data.length; index++) {
        var appt = data[index];
        
        if (appt.status == "pending"){
          var action = `<div class="actions text-center">
            <a href="/appointments/add_call_details?appointment_id=${appt.id}" data-remote="true" class="btn btn-success confirm-appointment btn-sm btn-square rounded-pill">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </a>
          </div>`
        }else {
          var action = `<span class="badge badge-sm"> ${appt.status} </span>`
        }
        appointment_row += `<tr>
                          <td>
                            <div class="" style="min-width: 80px;">
                              <p class="mb-1"> <b> 
                                ${appt.patient_name} </b>
                              </p>
                              <div>
                                <span class="icofont-ui-cell-phone p-0 me-2"> 
                                </span>
                                <small> ${appt.patient_mobile} </small>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="text-muted text-nowrap">
                              ${appt.patient_uid}
                            </div>
                          </td>
                          <td>
                            <div class="text-muted text-nowrap">
                              ${appt.visit_time}
                            </div>
                          </td>
                          <td>
                            ${appt.reminder_count} calls
                          </td>
                          <td>
                            ${action}
                          </td>
                        </tr>`
      }

      var list = `<div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Patient ID</th>
                  <th scope="col">Visit Time</th>
                  <th scope="col">Status</th>
                  <th scope="col">Actions</th> 
                </tr>
              </thead>
              <tbody>
                ${appointment_row}
              </tbody>
            </table>
          </div>`

      $(`#doctor-${doctor_id}-appointments`).text(res.meta.total_count)
      $el.parents('.doctor-appointments').next(".doctor-appt-table").html(list)
    });
  }
}