import { Controller } from "@hotwired/stimulus"
let debounce = require("lodash/debounce");


export default class extends Controller {
  initialize(){
    this.updateOnQuantity = debounce(this.updateOnQuantity, 500).bind(this)
  }
  
  connect() {
    console.log("Hello Pharmacy!")
  }

  loader(){
    // Add Loader
    $(event.target).find('#save-blog').hide()
    $("#loader").html('<i class="fa fa-circle-o-notch"></i>')
  }

  getMedicines(event){
    var prescriptionid = $(event.currentTarget).attr("data-prescriptionid");
    $.ajax({
      url: `${HOST}/pharmacy/medicines/?prescription_id=${prescriptionid}`,
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
      }
    });
  }

  fillPatient(event){
    var patientId = $(event.currentTarget).attr("data-patientId");
    $.ajax({
      url: `${HOST}/pharmacy/blank_entry/?patient_id=${patientId}`,
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        $(".pharmacy-bill-print").attr("data-patientid", patientId)
      }
    });
  }

  removeMedicine(event){
    $(event.target).parents("tr").remove()
    // $(".total-amount").text( sum("mrp") )
    // $(".total-discount").text( sum("discount") )
    $(".total-net").text( sum("net") )
  }

  selectMedicine(event){
    var medicineId = $(event.target).attr("data-medicine-id");
    var medicineName = $(event.target).attr("data-medicine-name");
    var td = $(event.target).parents("td")[1]
    $(td).find("input[name='treatment_medicine[medicine_id]']").val(medicineId)
    $(td).closest("tr").find("#returned").attr("data-medicine-id", medicineId);
    $(td).find("input[name='treatment_medicine[name]']").val(medicineName)
    window.fillMedicineMetaInfo($(td).find(".pharmacy_medicine"))
    $(event.target).parents(".med-sublist").remove()
    $(td).parents("tr").find(".remove-medicine").attr("data-invoice-item-id", medicineId)
    $(td).parents("tr").find(".edit-master").attr("href", `/admin/medicines/${medicineId}/edit`)
  }

  isReturned(event){
    
  }

  addMoreMedicine(event){
    var count = $(`.medicines-list > tbody > tr`).length - 2
    $(".medicines-list > tbody > tr:last-child").prev().after(`
      <tr>
        <td> ${count + 1} </td>
        <td class="p-1">
          <div class="form-group">
            <div class="form-group">
              <input type="text" name="treatment_medicine[name]" id="treatment_medicine_name" value="" class="form-control pharmacy_medicine" placeholder="Medicine" autocomplete="off" data-action="input->prescription#medicinesList">
              <input type="hidden" name="treatment_medicine[medicine_id]" value="">
            </div>
            <ul class="medicinesList custom-select-list"> </ul>
          </div>
        </td>

        <td class="p-1">
          <div class="form-group text-center quantity">
            <input type="text" name="quantity" id="quantity" data-action="input->pharmacy#updateOnQuantity" class="form-control"  style="margin: auto;">
          </div>
        </td>

        <td class="p-1">
          <div class="form-group text-center batch min-100" style="min-width: 100px;">
            <select name="batch" id="batch" data-medicine-id="" data-action="input->pharmacy#updateOnQuantity" 
            class="form-control w-100" 
            style="margin: auto;">
              <option> </option>
            </select>
          </div>

          <small class="d-flex justify-content-between"> 
            <span class="selected-batch-stock"> </span>
            <span class="selected-batch-expiry"> </span>
          </small>
        </td>

        <td class="p-1">
          <div class="form-group text-center returned">
            <input type="checkbox" name="returned" id="returned" data-medicine-id="" >
          </div>
        </td>

        <td class="p-1">
          <div class="form-group text-center stock">

          </div>
        </td>

        <td class="p-1">
          <div class="form-group packing">

          </div>
        </td>

        <td class="p-1">
          <div class="form-group text-center amount">
          </div>
        </td>

        <td class="p-1">
          <div class="form-group text-center mrp">
          </div>
        </td>

        <td class="p-1">
          <div class="form-group text-center discount">
          </div>
        </td>

        <td class="p-1">
          <div class="form-group text-center gst">
          </div>
        </td>

        <td class="p-1">
          <div class="form-group text-center net">
          </div>
        </td>
        
        <td class="p-1">
          <div class="text-center">
            <a target="_blank" class="btn ml-1 d-block text-white btn-sm btn-info edit-master float-right" href="/admin/medicines/">
              <i class="fa fa-pencil text-white"> </i>
            </a>
          </div>
        </td>
      </tr>
    `);
    $(".medicines-list > tbody > tr:last-child").prev().find("#treatment_medicine_name").focus();
  }

  updateOnQuantity(event){
    var el = $(event.target)
    var tr = $(event.target).closest("tr")
    var batchId = tr.find("#batch").val();
    var quantity = tr.find("#quantity").val();
    var medicineId = tr.find("input[name='treatment_medicine[medicine_id]']").val();

    var selectedMedicines =  $(`input[name='quantity']`).filter(function() {
      // Check if the input has a non-empty value
      return $(this).val().trim() !== '';
    });

    $(".totalMedicinesSelected").text(`${selectedMedicines.length} Selected`)
    $('.pharmacy-bill-print').hide()
    
    $.ajax({
      url: `${HOST}/api/v1/medicines/get_price_by_quantity?id=${medicineId}&quantity=${quantity}&batch_id=${batchId}`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        console.log(res)
        var options = '';
        res.payload.batches.map((b) => {
          if (tr.find(`#batch option[value='${b.id}']`).length == 0){
            options += `<option value="${b.id}" ${ (b.id == res.payload.selected_batch?.id) ? 'selected' : ''}> ${b.batch_no} </option>`
          }else{
            tr.find(`#batch option[value='${b.id}']`).text(b.batch_no)
          }
        })
        tr.find("#batch").append(options)
        tr.find("#batch").parents('td')
          .find('.selected-batch-stock').text(res.payload.selected_batch?.stock_left)

        if (res.payload.selected_batch?.stock_left < quantity){
          tr.find("#batch").parents('td')
          .find('.selected-batch-stock').addClass('text-danger')
        }else{
          tr.find("#batch").parents('td')
          .find('.selected-batch-stock').removeClass('text-danger')
        }
        tr.find("#batch").parents('td')
          .find('.selected-batch-expiry').text(res.payload.selected_batch?.expiry_date)
        tr.find("#batch").attr("data-medicine-id", res.payload.batches[0]?.medicine_id)
        try {
          if (tr.find('.returned input').is(":checked")){
            tr.find('.mrp').addClass("text-danger").text(`-${res.payload.mrp.toFixed(2)}`)
            tr.find('.amount').addClass("text-danger").text(`-${res.payload.unit_price.toFixed(2)}`)
            tr.find(".discount").addClass("text-danger").text(`-${res.payload.discount.toFixed(2)}%`)
            tr.find(".net").addClass("text-danger").text(`-${res.payload.grand_total.toFixed(2)}`)
          }else{
            tr.find('.mrp').text(res.payload.mrp.toFixed(2))
            tr.find('.amount').text(res.payload.unit_price.toFixed(2))
            tr.find(".discount").text(`${res.payload.discount.toFixed(2)}%`)
            tr.find(".gst").text(`${parseFloat(res.payload.gst)}`)
            tr.find(".net").text(res.payload.grand_total.toFixed(2))
          }
          window.fillMedicineMetaInfo(tr.find(".pharmacy_medicine"), medicineId)
          $('.pharmacy-bill-print').show() 
        }catch{
          $('.pharmacy-bill-print').show() 
        }
        // $(".total-amount").text( sum("mrp") )
        $(".total-net").text( sum("net") )
      }
    })
  }

  filterBillsOnDate(event){
    var date = $(event.target).val()
    $.ajax({
      url: `${HOST}/pharmacy/daily_collections?date=${date}`,
      headers: { "Authorization": `Token ${TOKEN}` }
    })
  }

  generateBill(event){
    flashToast("Generating Invoice...", "info")
    var $el = $(event.target)
    var prescriptionId = $el.attr("data-prescriptionid")
    var invoiceId = $el.attr("data-invoiceid")
    var patientId = $el.attr("data-patientid")
    var list = []; 

    // Hide button
    $el.hide()

    $(".medicines-list").find("tbody:not(.med-sublist tbody) tr").slice(0,-1).each( function() {
      if ($(this).find("td").length > 0){
        try {
          list.push({ 
            id: $(this).find("td").find("input[name='treatment_medicine[medicine_id]']").val(),
            name: $(this).find("td #treatment_medicine_name").val(),
            batch: $(this).find("td #batch").val().trim(),
            packing: $(this).find("td .packing").text().trim(),
            quantity: $(this).find("td #quantity").val(),
            amount: $(this).find("td .mrp").text().trim(),
            discount: $(this).find("td .discount").text().trim(),
            net: $(this).find("td .net").text().trim()
          })                                                   
        }catch {}
      }
    })

    // Send request to server to generate invoice
    $.ajax({
      url: `${HOST}/pharmacy/generate_bill`, 
      method: "POST",
      data: {
        prescription_id: prescriptionId,
        invoice_id: invoiceId,
        medicines: list,
        patient_id: patientId
      },
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        console.log(res)
        printPage(res.content);
        $(".pharmacy-bill-print").attr("data-invoiceid", res.invoice_id)
        $el.show()

        // Close modal
        $(".pharmacy-medicines-list button[aria-label='Close']").trigger("click")

        // Remove entry from pharmacy screen
        $(".pharmacy-appt-table").find(`tr[data-prescriptionId='${prescriptionId}']`).remove()
      },
      error: function(err){
        flashToast(`${err.responseJSON.status.message}`, "warning")
        $el.show()
      }
    })
  }

  refund(event){
    flashToast("Cancelling Invoice...", "info")
    var $el = $(event.target)
    var prescriptionId = $el.attr("data-prescriptionid")
    var invoiceId = $el.attr("data-invoiceid")

    // Send request to server to generate invoice
    $.ajax({
      url: `${HOST}/pharmacy/refund?invoice_id=${invoiceId}&prescription_id=${prescriptionId}`, 
      method: "GET",
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        flashToast("Invoice Cancelled", "success")
        $(".modal").modal('hide');
        $(".modal-backdrop.fade.show").remove()
      },
      error: function(err){
        flashToast(`${err.responseJSON.status.message}`, "warning")
      }
    })
  }

  reprint(event){
    flashToast("Reprinting Invoice...", "info")
    var $el = $(event.target)
    var prescriptionId = $el.attr("data-prescriptionid")
    var invoiceId = $el.attr("data-invoiceid")

    // Send request to server to generate invoice
    $.ajax({
      url: `${HOST}/pharmacy/reprint?invoice_id=${invoiceId}&prescription_id=${prescriptionId}`, 
      method: "GET",
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        printPage(res.content);
      },
      error: function(err){
        flashToast(`${err.responseJSON.status.message}`, "warning")
      }
    })
  }

  generateWalkingBill(event){
    var full_name = $("#search-patient").val();
    var $el = $(event.target);
    var patientId = $el.attr("data-patientid")
    var mobile = $("#patient_mobile").val();
    if( !full_name || !mobile ) {  
      flashToast(`Please fill missing patient details`, "warning")
      return; 
    } 

    // Hide button
    $el.hide()

    var list = []; 
    flashToast("Generating Invoice", "info")
    $(".medicines-list").find("tbody:not(.med-sublist tbody) tr").each( function() {
      if ($(this).find("td").length > 0){
        try {
          list.push({ 
            id: $(this).find("td").find("input[name='treatment_medicine[medicine_id]']").val(),
            name: $(this).find("td #treatment_medicine_name").val(),
            batch: $(this).find("td #batch").val(),
            packing: $(this).find("td .packing").text().trim(),
            quantity: $(this).find("td #quantity").val(),
            amount: $(this).find("td .mrp").text().trim(),
            discount: $(this).find("td .discount").text().trim(),
            net: $(this).find("td .net").text().trim()
          })                                 
        }catch {}
      }                  
    })

    // Send request to server to generate invoice
    $.ajax({
      url: `${HOST}/pharmacy/generate_bill`, 
      method: "POST",
      data: {
        patient: {
          full_name: full_name,
          dob: $("#patient_dob").val(),
          gender: $("#patient_gender").val(),
          mobile: mobile,
          email: $("#patient_email").val()
        },
        invoice_id: $(".pharmacy-bill-print").attr("data-invoice_id"),
        patient_id: patientId,
        medicines: list
      },
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        console.log(res)
        printPage(res.content);

        $(".pharmacy-bill-print").attr("data-invoice_id", res.invoice_id)

        // Show button
        $el.show()

        // Close modal
        $(".pharmacy-medicines-list button[aria-label='Close']").trigger("click")
      },
      error: function(err){
        console.log(err)

        // Show button
        $el.show()
        flashToast(
          `${err.responseJSON.status.message}`, "warning"
        )
      }
    })
  }

  editInvoice(event){
    var invoiceId = $(event.currentTarget).attr("data-invoiceid");
    $.ajax({
      url: `${HOST}/pharmacy/edit_invoice/?invoice_id=${invoiceId}`,
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
      }
    });
  }
}