import { Controller } from "@hotwired/stimulus"
import { contains } from "jquery";
let debounce = require("lodash/debounce");

export default class extends Controller {
  connect() {
    console.log("Prescription!")
    window.TOKEN = window.readCookie("_priac_jwt");
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.size > 0){
      setTimeout(() => {
        this.fillPatient(null)      
        window.history.replaceState(null, '', window.location.pathname);
      }, 1000);
    }
  }

  // loader(event){
  //   // Add Loader
  //   $(event.target).find('#save-blog').hide()
  //   $("#loader").html('<i class="fa fa-circle-o-notch"></i>')
  // }

  fillPatient(event){
    var block = this 
    const urlParams = new URLSearchParams(window.location.search);
    if (!event){
      var patientId = urlParams.get("patient_id")
      var appointmentId = urlParams.get("appointment_id")
    }else{
      var patientId = $(event.target).attr('data-patientid') 
      var appointmentId = $(event.target).attr('data-appointmentid')
    }

    if (!patientId){
      patientId = $(event.target).parents("td").attr('data-patientid');
    }
    if(!appointmentId){
      appointmentId = $(event.target).parents("td").attr('data-appointmentid');
    }

    $.ajax({
      url:  `${HOST}/api/v1/patients/${patientId}`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        var patientParams = res.payload

        // Reset prints buttons
        // $("#save_update, #saveprint").show()
        // $("#prescription_reprint").attr("data-prescriptionid", null).addClass("d-none")


        fillPatientData(patientParams)
        createAppointmentTabs(res)
        $("#save_update, #saveprint, .update-patient-info").attr("data-patientId", patientId)
        $("#save_update, #saveprint").attr("data-appointmentid", appointmentId)
        
        // Get today's appointment data
        var appointment = res.payload.appointments.filter(m => 
            (m.id == appointmentId) || 
            (m.status == "completed") || 
            (m.prescription_id != null && m.status == "paid")
        )[0]

        if (appointment){
            // Fill Appointment Fields
            for (const k in appointment) {
            var apptVal = appointment[k] || "";
            var $apptElement = $(`#appointment_${k}`)
            
            if(!$($apptElement).is("select")){
              $(`input[name="appointment[${k}]"]`).val(apptVal)
            }else if ($apptElement.is(":checkbox")){
              $(`#appointment_${k}`).prop('checked', apptVal);
            }
            else if(k.indexOf("_id") == -1){
              $(`button[data-id='appointment_${k}']`).trigger("click")
              $(`button[data-id='appointment_${k}']`)
                .next(".dropdown-menu")
                .find(`span:contains(${ humanize(apptVal) })`)
                .parent().trigger("click")
            }else{
              $(`#appointment_${k}`).val(apptVal).trigger('change')
              // selectDropdownById(`appointment_${k}`, apptVal)
            }
            if (["tobacco", "alcohol", "smoking"].indexOf(k) != -1){
              $(`#appointment_${k}`).prop('checked', apptVal);
            }
          }

          // Fill Investigations by getting the full appointment details
          $.ajax({
            url: `${HOST}/api/v1/appointments/${appointment.id}`,
            headers: { "Authorization": `Token ${TOKEN}` },
            success:  function(res){
              
              // Get list of all investigations to populate dropdown
              $.ajax({
                url: "/prescription/investigations",
                headers: { "Authorization": `Token ${TOKEN}` },
                success: function(r){
                  var options = '';
                  for (let index = 0; index < r.investigations.length; index++) {
                    const element = r.investigations[index];
                    options += `<option value='${element}'>${element}</option>`                    
                  }
                  $(`#medical_param`).empty('')
                  $(`#medical_param`).html(options).trigger("change")
                  newInvestigations(res.payload.ready_investigations, res.payload.id) 
                  $(".investigations-panel").show()
                }
              })
            }
          })
        }

        // If today's appointment is present and also the prescription is there then load the prescription data 
        if (appointment && appointment.prescription_id){
          block.getPrescription(appointment)
        }else{
          // Check and Carry forward last completed prescription data for today's appointment case
          var completedAppointment = res.payload.appointments.filter(m => (m.status == "completed" || (m.prescription_id != null && m.status == "paid")))[0]

          // if the completd visit is of today's date
          if (completedAppointment?.visit_date == (new Date()).toISOString().split('T')[0]){
            appointment = completedAppointment
          }
          
          if (completedAppointment){
            block.getPrescription(completedAppointment)
          }else{
            fillPrescriptionData(null)
          }
        }

        $("#save_update, #saveprint").attr("data-appointmentid", appointment?.id || null);
        $("#save_update, #saveprint").attr("data-prescriptionid", appointment?.prescription_id || null); 
        
        // If searched another visit from top that is not of today's date then allow only reprint
        // debugger
        // if (!(appointment?.id || null)){
        //   $("#save_update, #saveprint").hide()
        //   $("#prescription_reprint").attr("data-prescriptionid", completedAppointment?.prescription_id || null).removeClass("d-none")
        // }
      }
    })
  }
  
  getPrescription(appt){
    $(".show_reports").attr("href", `/prescription/medical_reports?patient_id=${appt.patient_id}`)
    
    var prescriptionId = appt.prescription_id;

    // Clean the table 
    $(`.treatment-table tbody`).html(null);
    
    // Fill prescription data of past completed appointment
    if (prescriptionId){
      fillPrescriptionData(prescriptionId)
      // $("#save_update, #saveprint").attr("data-prescriptionid", prescriptionId);
    }else{
      fillPrescriptionData(null)
    }

    // Fill the ids or futther processing
    $("#patient-consultancy").removeClass('d-none').text(`Consultancy: ${appt.consultancy}`)

    $(".medicalTestsList").html(null)
  }

  getMedicalTest(event){
    var name = $(event.target).val()
    $.ajax({
      url: `/api/v1/medical_tests/show_by_name?name=${name}`,
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        console.log(res)
        $("#medical_code").text(res.payload.code)
        $("#medical_cost").text(res.payload.amount)
        $("#generated_at").text(new Date().toLocaleDateString())
        $("#female_minmax").append( `${res.payload.female_min || ''} - ${res.payload.female_max || ''}`)
        $("#male_minmax").append( `${res.payload.male_min || ''} - ${res.payload.male_max || ''}`)
        $(`#medical_test_id`).val(res.payload.id)
      },
      error: function(err){
        console.log(err)
      } 
    })
  }


  reportSave(event){
    var $form = $(event.target);
    var data = {
      medical_test_report: {
        medical_test_id: $form.find(`#medical_test_id`).val(),
        patient_id: $form.find(`#patient_id`).val(),
        medical_test_category_id: $form.parents("tr").find(`#medical_test_category_id`).val(),
        value: $form.find(`#value`).val()
      }
    }
    $.ajax({ 
      url:`/api/v1/medical_test_reports/`,
      headers: { "Authorization": `Token ${TOKEN}` },
      method: "POST",
      data: data,
      success: function(res){
        console.log(res)
        flashToast("Report Value Saved", "info")
      },
      error: function(res){
        console.log(res)
        flashToast("Test Not Exist", "warning")
      }
    });
  }

  addMedicalParam(event){
    var param = $(event.target).val()
    var fields = `
    <div data-test="${param.toUpperCase()}">
      <i class="text-danger fa fa-times" data-action="click->prescription#removeInvestigation"> 
      </i>
      <label class="display-7"> ${param} </label>
      <div class="d-flex mr-3">
        <div class="small-field">
          <input type="text" name="investigation[before_${param.toLowerCase()}]" id="before_${param.toLowerCase()}" class="form-control" placeholder="Before ${param}">
        </div>
        <div class="small-field">
          <input type="text" name="investigation[after_${param.toLowerCase()}]" id="${param.toLowerCase()}" class="form-control" placeholder="${param}">
        </div>
      </div>
    </div>`
    $(`.investigations`).append(fields)
    $(`#medical_param option[value='${param}']`).remove()
    $('#medical_param').selectpicker('refresh');
  }

  fillSpecialInstructions(event){
    event.preventDefault();
    $.get({
      url: `${HOST}/api/v1/prescriptions/special_instructions?code=${event.target.text}`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        console.log(res)
        // $(event.target).hide()
        let oldValue = $("#prescription_special_instructions_").val()
                  .split(",")
                  .map(item => item.trim());
          oldValue.push(res.payload.value)
          var inst = [...new Set(oldValue)];
          console.log(inst)
        $("#prescription_special_instructions_").val( `${inst.join(", ")}`)
      }
    })
  }

  fillTests(event){
    event.preventDefault();
    $.ajax({
      url: `${HOST}/api/v1/medical_tests?shortcode=${event.target.text}`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        console.log(res)
        var oldValue = $("#prescription_medical_tests_")
                .val()
        if (oldValue){
          oldValue = oldValue.split(",")
                .map(item => item.trim());
        }else {
          oldValue = []
        }
        var data = res.payload.medical_tests;
        for (let index = 0; index < data.length; index++) {
          if (data[index].name){
            oldValue.push(data[index].name)
          }
        }
        var tests = [...new Set(oldValue)];
        console.log(tests)
        $("#prescription_medical_tests_").val(`${tests.join(", ")}`)
      }
    })
  }

  removeMedicine(event){
    $(event.target).parents("tr")[0].remove()
  }

  dump(event){
    let input = $(event.target).parent().prev().find("input[type='text']")
    $(event.target).parent().find("li").removeClass("selected")
    $(event.target).addClass("selected")
    window.selectOption(input)
  }

  selectOption(event){
    let input = $(event.target).parent().prev().find("textarea")
    $(event.target).parent().find("li").removeClass("selected")
    $(event.target).addClass("selected")
    window.selectOption(input)
  }

  medicalTestsList(event) {
    // Clear the previous debounce timer if it exists
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    // Set a new debounce timer for 500ms
    this.debounceTimer = setTimeout(() => {
      this.performMedicalTestsList(event);
    }, 500);
  }

  performMedicalTestsList(event) {

    var list = $(event.target).parent().next(".medicalTestsList")
    if (event.target.value == ''){
      list.hide()
      list.html(null);
      return;
    }
    
    var query = event.target.value.split(",").pop().trim().toLowerCase()
    
    var html = '';
    const allTests = JSON.parse($(".all-medicaltests").text());
  
    const tests = allTests.filter(function(r){
      return r.name.toLowerCase().startsWith(query) || r.code.toLowerCase().startsWith(query) 
    }).sort((a, b) => a.name.length - b.name.length).splice(0, 10)

    if (tests.length == 0){
      list.hide()
      list.html(null);
      return;
    }

    for (let index = 0; index < tests.length; index++) {
      let med = tests[index];
      if (index == 0){
        var selected = 'selected';
      }else{
        var selected = '';
      }
      var matched_name = med.name
      html += `<li class="med ${selected} p-2" data-action="click->prescription#selectOption"> 
        ${matched_name}
      </li>`;
    }
    list.show()
    list.html( html )
  }


  followUpList(event){
    var list = $(event.target).parent().next(".followUpList")
    if (event.target.value == ''){
      list.hide()
      list.html(null);
      return;
    }
    var query = event.target.value.split(",").pop().trim()
    $.ajax({
      url: `${HOST}/api/v1/medicines/followup?q=${query}`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        console.log(res)
        var html = '';
        // var tests = res.payload.medical_tests;
        var tests = res.payload.followups;
        for (let index = 0; index < tests.length; index++) {
          let med = tests[index];
          if (index == 0){
            var selected = 'selected';
          }else{
            var selected = '';
          }
          var matched_name = med
          html += `<li class="med ${selected} p-2" data-action="click->prescription#selectOption"> 
            ${matched_name}
          </li>`;
        }
        list.show()
        list.html( html )
      }
    })
  }

  videosList(event){
    var list = $(event.target).parent().next(".videosList")
    if (event.target.value == ''){
      list.hide()
      list.html(null);
      return;
    }
    var query = event.target.value.split(",").pop().trim()
    $.ajax({
      url: `${HOST}/api/v1/medicines/videos?q=${query}`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        console.log(res)
        var html = '';

        var videos = res.payload.videos;
        for (let index = 0; index < videos.length; index++) {
          let med = videos[index];
          if (index == 0){
            var selected = 'selected';
          }else{
            var selected = '';
          }
          var matched_name = med
          html += `<li class="med ${selected} p-2" data-action="click->prescription#selectOption"> 
            ${matched_name}
          </li>`;
        }
        list.show()
        list.html( html )
      }
    })
  }

  medicinesList(event){
    var list = $(event.target).parent().next(".medicinesList")
    if (event.target.value == ''){
      list.hide()
      list.html(null);
      return;
    }
    
    var html = '';
    const searchQuery = event.target.value ? event.target.value.toLowerCase() : '';
    const allMedicines = JSON.parse($(".all-medicines").text());
    var allMedicineSalts;
    if ($(".all-medicinesalt").length > 0){
      allMedicineSalts = JSON.parse($(".all-medicinesalt").text());
    }else{
      allMedicineSalts = []
    }


    // Filter MedicineSalts
    const filteredSalts = searchQuery 
      ? allMedicineSalts.filter(salt => salt.name?.toLowerCase()?.startsWith(searchQuery))
      : allMedicineSalts;

    // Filter Medicines
    let filteredMedicines = allMedicines;
    if (searchQuery) {
      filteredMedicines = filteredMedicines.filter(medicine =>
        medicine.name?.toLowerCase()?.startsWith(searchQuery)
      );
    }

    // Combine, limit, and sort the results
    const medicines = [...filteredMedicines, ...filteredSalts]
      .slice(0, 10)
      .sort((a, b) => a.name.length - b.name.length);

    for (let index = 0; index < medicines.length; index++) {
      let med = medicines[index];
      let type = "medicine"
      if (!med.company){
        type = "medicine_salt"
      }

      if (index == 0){
        var selected = 'selected';
      }else{
        var selected = '';
      }
      
      var matched_name = med.name.toLowerCase().includes(event.target.value.toLowerCase()) ? med.name : med.details
      html += `<li class="med ${selected} p-2" data-medicineid="${med.id}" 
      data-classtype="${type}"
      data-action="click->prescription#dump"> 
        ${matched_name?.trim()}
      </li>`;
    }
    list.show()
    list.html( html )
  }

  descriptionsList(event){
    var list = $(event.target).parent().next(".descriptionsList")
    if (event.target.value == 0){
      list.hide()
      list.html(null);
      return;
    }

    let html = '';
    const alldescriptions = JSON.parse($(".all-descriptions").text());
    const descriptions = alldescriptions.filter(function(remark){
      return remark.search_code.toLowerCase().startsWith(event.target.value.toLowerCase()) || remark.value.toLowerCase().startsWith(event.target.value?.toLowerCase())
    }).sort((a, b) => a.value.length - b.value.length)

    for (let index = 0; index < descriptions.length; index++) {
      let med = descriptions[index];
      if (index == 0){
        var selected = 'selected';
      }else{
        var selected = '';
      }
      html += `<li class="med ${selected} p-2" data-action="click->prescription#dump"> ${med.value} </li>`;
    }

    list.show()
    list.html( html )
  }

  remarksList(event){
    var list = $(event.target).parent().next(".remarksList")
    if (event.target.value == 0){
      list.hide()
      list.html(null);
      return;
    }

    let html = '';
    const allRemarks = JSON.parse($(".all-remarks").text());
  
    const remarks = allRemarks.filter(function(remark){
      return remark.value.toLowerCase().startsWith(event.target.value.toLowerCase())
    }).sort((a, b) => a.value.length - b.value.length)

    if (remarks.length == 0){
      list.hide()
      list.html(null);
      return;
    }
    for (let index = 0; index < remarks.length; index++) {
      let med = remarks[index];
      if (index == 0){
        var selected = 'selected';
      }else{
        var selected = '';
      }
      html += `<li class="med ${selected} p-2" data-action="click->prescription#dump"> ${med.value} </li>`;
    }
    list.show()
    list.html( html )
  }
  
  fillAppointment(event){
    var block = this;
    var prescriptionId = $(event.target).attr("data-prescriptionid")

    // If perscription is not generated yet, pull previous prescription
    if (!prescriptionId){
      var pastPrescriptionId = $(event.target).parent().prev().find(".appointment-tabs").attr("data-prescriptionId")
    }

    var appointmentId = $(event.target).attr("data-appointmentid")
    $(".appts span").removeClass("bg-theme")
    $(event.target).addClass("bg-theme")

    if (appointmentId){
      $.ajax({
        url: `${HOST}/api/v1/appointments/${appointmentId}`,
        headers: { "Authorization": `Token ${TOKEN}` },
        success:  function(res){
          console.log(res)
          var medParams = res.payload.ready_investigations
          newInvestigations(medParams, appointmentId)
        }
      })

      $("#saveprint, #save_update").attr("data-prescriptionid", prescriptionId)
      fillPrescriptionData(prescriptionId || pastPrescriptionId)
    }else{
      var  patientId = $("#save_update, #saveprint").attr("data-patientId")
      $.ajax({
        url: `${HOST}/api/v1/appointments/ready_investigations?patient_id=${ patientId}`,
        headers: { "Authorization": `Token ${TOKEN}` },
        success:  function(res){
          console.log(res)
          newInvestigations(res.payload.tests, '')
        }
      })
    }
  }

  removeInvestigation(event){
    var $el = $(event.target)
    var appointmentId = $el.attr("data-appointmentid");
    var test = $el.parent().attr("data-test");
    if (appointmentId){
      $.ajax({
        url: `/api/v1/appointments/${appointmentId}/remove_investigations?test=${test}`,
        method: "PUT",
        headers: { "Authorization": `Token ${TOKEN}` },
        success: function(res){
          console.log(res)
          $el.parent().remove()
          $(`#medical_param`).append(`<option value='${test}'>${test}</option>`)
          $('#medical_param').selectpicker('refresh');
        },
        error: function(errr){
          console.log(errr)
        }
      })
    }else{
      $el.parent().remove()
      $(`#medical_param`).append(`<option value='${test}'>${test}</option>`)
      $('#medical_param').selectpicker('refresh');
    }
  }

  treatmentData(event){
    var treatmentId = event.target.value
    if (treatmentId){
      fillTreatmentData(treatmentId)
    }else{
      clearTreatmentAndPrescription()
    }
  }

  loadReferrers(event){
    var text = $(event.target).val();
    var list = $(event.target).parent().next(".referrersList")
    if (event.target.value == ''){
      list.hide()
      list.html(null);
      return;
    }

    $.ajax({ 
      url:`${HOST}/api/v1/referrers?q=${event.target.value}`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        console.log(res)
        var html = '';
        var referrers = res.payload.referrers;
        for (let index = 0; index < referrers.length; index++) {
          let med = referrers[index];
          if (index == 0){
            var selected = 'selected';
          }else{
            var selected = '';
          }
          html += `<li class="med ${selected} p-2" data-action="click->prescription#dump"> 
            ${med.full_name || ''} ${med.qualification || ''} ${med.profession || ''} ${med.address || ''} ${med.phone || ''} ${med.email || ''}
            
            <a data-remote="true" data-referrer_id="${med.id}" data-action="click->prescription#referrerForm" class="text-dark pl-2"><i class="fa fa-pencil">  </i></a>
          </li>`;
        }
        list.show()
        list.html(`<a class="btn btn-warning btn-sm d-block" data-remote="true" data-remote="true" data-action="click->prescription#referrerForm"> Add Referrer </a> ${html}`)
      }
    })
  }

  editSpellingForm(event){
    var type = $(event.target).attr("data-type")
    var entityId = $(event.target).parent().find('select').val();
    var text = $(event.target).parent().find(`select option[value="${entityId}"]`).text()
    $(".modal").modal('hide');
    $(".modal-container").html(`
      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" data-controller="prescription" aria-hidden="true">
        <div class="modal-dialog modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="h5" id="exampleModalLabel">  Update / Create ${type}</h5>
            </div>

            <div class="modal-body">
              <input type="text" id="new-spelling" value="${text}" class="mb-2 form-control" placeholder="Edit option">
              <span class="btn btn-sm" data-type="${type}" data-action="click->prescription#saveSpelling" data-id="${entityId}">Save</span>
            </div>
          </div>
        </div>
      </div>
    `);
    $(".modal").modal('show');
  }

  saveSpelling(event){
    var type = $(event.target).attr("data-type")
    var $el  = $(`#patient_${type}_id`)
    var entityId = $(event.target).attr("data-id")
    var value = $("#new-spelling").val()
    $.ajax({
      url: `${HOST}/api/v1/master/save_spelling/`,
      headers: { "Authorization": `Token ${TOKEN}` },
      method: "put",
      data: {
        value: value,
        id: entityId,
        type: type
      },
      success: function(res){
        console.log(res)
        $(".modal").modal('hide');
        $el.find(`option[value='${res.payload.id}']`).remove()
        $el.append($('<option>', {
          value: res.payload.id,
          text: res.payload.title
        })).selectpicker('refresh');
        $el.val(res.payload.id).trigger("change")
      },
      error: function(err){
        console.log(err)
        $("#new-spelling").css({"border": "1px solid red"})
        $("#new-spelling").after(`<span class="d-block err text-danger"> ${err.responseJSON.status.message}</span>`)
      }
    })
  }

  referrerForm(event){
    event.preventDefault()
    event.stopPropagation()
    var referrerId = $(event.target).closest("a").attr("data-referrer_id");

    if (!referrerId){
      referrerId = $("#appointment_referrer_id").val() 
    }
    $.get(`/prescription/referrer_form?referrer_id=${referrerId}`)
    return false;
  }

  saveReferrer(event){
    event.preventDefault()
    var $form = $(event.target)
    var referrer_id = $form.attr("data-referrer-id")
    if (referrer_id){
      var type = "PUT" 
    }else{
      var type = "POST"
    }
    $.ajax({
      url: `${HOST}/api/v1/referrers/${referrer_id || ''}`,
      headers: { "Authorization": `Token ${TOKEN}` },
      method: type,
      data: {
        data: $form.serializeArray(),
      },
      success: function(res){
        console.log(res)
        if (res.payload){
          flashToast("Referrer Saved","info")
          $(".modal").modal('hide');
          $("#appointment_referrer_id").append(`
            <option value="${res.payload.id}">
              ${res.payload.full_name} - ${res.payload.address}
            </option>
          `)
            .val(res.payload.id)
            .trigger('change')
            .selectpicker('refresh');
        }
      },
      error: function(err){
        console.log(err)
        flashToast(err.responseJSON.status.message, "warning")
      }
    })
  }

  cancel(event){
    var appointmentId = $(event.target).closest("a").attr('data-appointmentId');
    var tr = $(event.target).parents("tr");
    $.ajax({
      url: `${HOST}/api/v1/appointments/${appointmentId}/cancel`,
      headers: { "Authorization": `Token ${TOKEN}` },
      method: "put",
      success: function(res){
        console.log(res)
        tr.remove();
      }
    })
  }

  loadAllAppointments(event){
    const patientId = $(event.target).attr('data-patientid');
    $.ajax({
      url:  `${HOST}/api/v1/patients/${patientId}?appointments=true`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(res){
        createAppointmentTabs(res, "all")
      }
    })
  }

  referralTemplate(event){
    event.stopPropagation()
    var referrerId = $("#appointment_referrer_id").val() 
    var patientId = $("#saveprint").attr("data-patientid")
    if (!patientId){
      flashToast("No Patient Selected", "warning")
      return;
    }
    $.get(`/prescription/referrer_template?referrer_id=${referrerId}&patient_id=${patientId}`, function(){
      $(".send-email").attr("data-referrerid", referrerId) 
    })
  }

  sendReferralMail(event){
    var referrerId = $(".send-email").attr("data-referrerid") 
    var patientId = $("#saveprint").attr("data-patientid")
    var content = referrerTemplate.getData()

    $.ajax({
      url: `/prescription/send_referrer_mail`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      method: "POST",
      data: {
        content: content,
        referrer_id: referrerId,
        patient_id: patientId
      },
      success: function(res){
        flashToast("Mail sent Successfully", "info")
      }
    })
  }

  save(event){
    event.preventDefault();
    this.savePrescription(event.target, false)
  }

  savePrescription(el, print){
    var $form = $(el).parents('form')
    var appointment_id = $(el).attr("data-appointmentid");
    var prescription_id = $(el).attr("data-prescriptionid");
    var opts = { 
      data: $form.serializeArray(),
      appointment_id: appointment_id,
      prescription_id: prescription_id
    }
    if (print){
      opts['prescription_print'] = true
    }
    $.ajax({
      url: `/api/v1/prescriptions/`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      method: "POST",
      data: opts, 
      success: function(res){
        console.log(res)
        if (res.status.status == "SUCCESS"){
          $("#save_update, #saveprint").attr("data-prescriptionid", res.payload.id);
          $(`.appointment-tabs[data-appointmentid='${appointment_id}']`).attr("data-prescriptionid", res.payload.id);
          flashToast("Prescription Updated Successfully", "info")
          if (res.payload.id && print){
            flashToast("Generating Prescription", "info")
            printPage(res.payload.content);
            $(`#patient-visit-${res.payload.patient_id}`).parents("tr").remove()
          }
        }else{
          flashToast(err.responseJSON.status.message, "warning")
        } 
      },
      error: function(err){
        try {
          var msg = err.responseJSON.status.message
        }catch {
          var msg = "Something Happened. Please check the values again"
        }
        flashToast(msg, "warning")
      }
    })
  }

  printPrescription(event){
    event.preventDefault()
    this.savePrescription(event.target, true)
    $.ajax({
      url:  `${HOST}/prescription/reload_visits`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(){}
    })
    return false;
  }

  printReferral(event){
    var referrerId = $(event.target).attr("data-referrer-id");
    var patientId = $("#saveprint").attr("data-patientid");
    var appointmentId = $("#saveprint").attr("data-appointmentId");
    var content = referrerTemplate.getData();

    content = `<div class=""> <hr> ${content} </div>`;

    $.ajax({
      url: `/prescription/print_referral`,
      method: "POST",
      headers: { "Authorization": `Token ${TOKEN}` },
      data:{
        content: content,
        referrer_id: referrerId,
        appointment_id: appointmentId,
        patient_id: patientId
      },
      success: function(res){
        console.log(res)
        printPage(res.payload.content); 
      },
      error: function(err){
        flashToast("Something Happened. Try again", "warning")
      }
    })
  }

  getCounts(event){
    var doctorId = $(event.currentTarget).attr("data-doctorid")
    $.ajax({
      url:  `${HOST}/prescription/get_counts?doctor_id=${doctorId}`, 
      headers: { "Authorization": `Token ${TOKEN}` },
      success: function(){}
    })
  }

  newVisit(event){
    takeAppointment()
    var context = this;
    $("#save_update, #saveprint").attr("data-prescriptionid", null)
    setTimeout(function(){ context.savePrescription($("#save_update")[0], false) }, 2000);
  }
}