import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Hello Lab")
  }

  saveMedicalRecord(event){
    var testId = $(event.target).attr("data-medical-test-id");
    var mtrId = $(event.target).attr("data-medical-test-report-id");
    var patientId = $(event.target).attr("data-patient-id");
    var invoiceItemId = $(event.target).attr("data-invoice-item-id");
    var compositeId = $(event.target).attr("data-composite-parent-test-id");
    const value = $(event.target).parent().find("#medical_report_value").val()

    var data = {
      id: mtrId,
      medical_test_report: {
        medical_test_id: testId,
        patient_id: patientId,
        value: value,
        invoice_item_id: invoiceItemId
      }
    }

    if (compositeId){
      data.medical_test_report.composite_parent_test_id = compositeId
    }

    $.ajax({ 
      url:`/api/v1/medical_test_reports/`,
      headers: { "Authorization": `Token ${TOKEN}` },
      method: "POST",
      data: data, 
      success: function(res){
        console.log(res)
        flashToast("Report Value Saved", "info")
      },
      error: function(res){
        console.log(res)
        flashToast(res.responseJSON.status.message, "warning")
      }
    });
    return false;
  }

  beforeCancelMedicalRecord(event){ return; }

  cancelMedicalRecord(event){
    var tr = $(event.currentTarget).parents("tr")
    var testId = $(event.currentTarget).attr("data-invoice-item-id");
    $.ajax({ 
      url:`/api/v1/invoices/remove_item?item_id=${testId}`,
      headers: { "Authorization": `Token ${TOKEN}` },
      method: "DELETE",
      success: function(res){
        console.log(res)
        tr.remove();
        flashToast("Test Cancelled", "info")
      },
      error: function(res){
        console.log(res)
        flashToast(res.responseJSON.status.message, "warning")
      }
    });
  }

  // filterVisitsOnDoctor(event){
  //   var doctorId = $(event.target).val()
  //   var date = $("#visit_date").val()
  //   $.ajax({
  //     url: `/lab/filter_tests?visit_date=${date}&doctor_id=${doctorId}`
  //   })
  // }

  filterVisitsOnDate(event){
    var date = $(event.target).val()
    var doctorId = $("#doctor_id").val()
    $.ajax({
      url: `/lab/filter_tests?date=${date}`
    })
  }

  fillPatient(event){
    var patient_id = $(event.target).attr('data-patientid')
    var url = `/lab/previous_reports?patient_id=${patient_id}`
    $.ajax({
      url: url
    })
  }

  setTestId(event){
    var testId = $(event.target).val()
    $(".new_test").attr("data-medical-test-id", testId)
  }

  printReport(event){
    var date = $("#paid_date").val()
    var patient_id = $(event.currentTarget).attr("data-patient_id")
    var mobile = $(event.currentTarget).attr("data-mobile")
    const testsToBePrinted = []; 
    $("input[name='m-test']:checked").map( (a, b)=> { testsToBePrinted.push(b.value) })
    
    var obj = {
      paid_date: date,
      mobile: mobile,
      test_ids: testsToBePrinted.join(","),
      patient_id: patient_id
    }
    $.ajax({
      url: `${HOST}/api/v1/medical_test_reports/print`,
      headers: { "Authorization": `Token ${TOKEN}` },
      method: "POST",
      data: obj,
      success: function(inv){
        flashToast("Generating Report", "info")
        if (inv.payload.reports.length > 0){
          window.printPage(inv.payload.reports[0]);
        }else{
          flashToast("Something Happened", "warning")
        }
      },
      error: function(){
        flashToast("Something Happened", "warning")
      }
    })
  }

}